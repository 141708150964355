import { useNavigate } from "react-router-dom"
import video from "../../Assets/Images/Background-video.mp4"
import config from "../../Pages/config"

const Section1 = () => {

    const Navigate = useNavigate()

    return(
        <div className="Section1-Main">
            <div className="video-container">
                <video
                    className='background-video'
                    autoPlay
                    loop
                    muted
                    src={video}
                />
                <div className="video-overlay"></div>
            </div>
            <div className="row N ">
                <div className="col-12 col-xl-12 d-flex justify-content-start align-items-end p-0">
                    <div className='Section1-header'>
                        <h1 className='py-3 pe-xl-4 ps-1 ps-xl-4 tagline overflow-hidden py-md-0 '>Awaken Your Inner Power and<br/> Transform Your Life</h1>
                        
                    </div>
                    <div className='flex-column flex-xl-row justify-content-betweeen w-100 py-2 px-1 px-md-4 header-content'>
                    <h3 className="mt-2 mt-md-0 ms-0 ms-md-5 ps-3 py-md-4 fs-2 fs-md-2 font-weight-500 text-left">
                        Schedule a free Consultation Call
                    </h3>
                    <button className="me-2 me-md-5 mt-1 mt-md-0 card-work-button" onClick={() => Navigate(`${config.appUrl}contactus`)}>
                        Book Now&nbsp;<span><i className="bi bi-chevron-right"></i></span>
                    </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section1
