import { Helmet } from "react-helmet";
import Footer from "../../Components/Common/Footer/footer";
import Navbar from "../../Components/Common/Navbar/Navbar";
import { InlineWidget } from "react-calendly";
import { useLocation } from "react-router-dom";
        
const ConsultationCall = () => {

  const location = useLocation(); // Get the current location
  const queryParams = new URLSearchParams(location.search); // Parse the query params
  const name = queryParams.get('name'); // Get the name
  const email = queryParams.get('email'); // Get the email

  return (
    <div className="BookaCall">
      <Helmet>
        <title>Consultation call - artofselfhealing.ca</title>
        <meta name="description" content="Send us a Message! Get in touch with us." />
      </Helmet>
      <Navbar cart={1}/>
      <div className="Section-main ">
        <h3 className="h4C text-center pt-xl-5">Please Select Date and Time As per Your Availability or Text on 416-887-9162.</h3>
        <div className="row">
          <InlineWidget url={`https://calendly.com/info-39901/ash-consultation-call?name=${name}&email=${email}`} styles={{height:'700px' ,marginTop:'0'}} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ConsultationCall;
