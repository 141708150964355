// data.js
import { useEffect, useState } from "react";
import config from "../../../Pages/config";
import Swal from "sweetalert2";
import axios from "axios";




export const courses = [
    { id: 1, title: 'Reiki Healing Service', link: `${config.appUrl}local/reiki-services` },
    { id: 2, title: 'Healing Massage', link: `${config.appUrl}local/massage-therapy-non-rmt-massages` },
    { id: 3, title: 'Yoga Therpy', link: `${config.appUrl}online-wellness/yoga-2` },
    { id: 4, title: 'Mastering Effortless Manifestation', link: `${config.appUrl}mastering-effortless-manifestation` },
    { id: 5, title: 'Long-Distance Reiki Healing Service', link: `${config.appUrl}online-wellness/long-distance-reiki` },
    { id: 6, title: 'Pranayama (Breath Work) Service', link: `${config.appUrl}online-wellness/pranayama-breath-work` },
    { id: 7, title: 'Meditation Service', link: `${config.appUrl}online-wellness/meditation-2` },
    { id: 8, title: 'Usui Reiki Level 1 Certification', link: `${config.appUrl}live/reiki-certification` },
    { id: 9, title: 'Usui Reiki Level 2 Certification', link: `${config.appUrl}live/usui-reiki-level-2-certification` },
    { id: 10, title: 'Usui Reiki Level 3 Certification', link: `${config.appUrl}live/usui-reiki-level-3-course` },
    { id: 11, title: 'Crystal Reiki Master Certification', link: `${config.appUrl}live/Crystal-Reiki-Master-Certification` },
    { id: 12, title: 'Mastering Deep Sleep Program', link: `${config.appUrl}live/mastering-deep-sleep` },
    { id: 13, title: 'Sleep Code Book', link: `${config.appUrl}sleep-code-e-book/` },
];

// Fetch blogs function
const fetchBlogs = async () => {
    try {
        const response = await axios.get(`${config.backendlink}blogs/get`);
        return response.data.data.map(blog => ({
            id: blog.id,
            title: blog.title,
            link: `${config.appUrl}blog/${blog.slug}` // Construct the link using the slug
        }));
    } catch (error) {
        console.error('Error fetching blogs:', error);
        return []; // Handle error appropriately
    }
};

// Custom hook to use blogs
export const useBlogs = () => {
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const getBlogs = async () => {
            try {
                const fetchedBlogs = await fetchBlogs();
                setBlogs(fetchedBlogs);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        getBlogs();
    }, []);

    return { blogs, loading, error };
};

// Optional: Export getBlogs for direct fetching
export const getBlogs = async () => {
    const blogs = await fetchBlogs();
    return blogs;
};