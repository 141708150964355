import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Navbar.css'; // Import your CSS file for styling
import { Link, } from 'react-router-dom';
import Logo from '../../../Assets/Images/Logo_new.png';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Import Bootstrap JavaScript
import config from '../../../Pages/config';
import { useLocation } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';
import { courses, useBlogs } from './data';

const Navbar = (cart=false) => {
    const location = useLocation()
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [cartCount,setCartCount] = useState(0);
    const navigate = useNavigate();

    const {blogs} = useBlogs();

    const handleSearchChange = (event) => {
        const query = event.target.value;
        setSearchQuery(query);

        if (query) {
            const filteredCourses = courses.filter(course =>
                course.title.toLowerCase().includes(query.toLowerCase())
            );
            const filteredBlogs = blogs.filter(blog =>
                blog.title.toLowerCase().includes(query.toLowerCase())
            );
            setSuggestions({ courses: filteredCourses, blogs: filteredBlogs });
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionClick = (link) => {
        navigate(link); // Navigate to the specific link
        setSearchQuery(''); // Clear the search input
        setSuggestions([]); // Clear suggestions
        setIsSearchOpen(false);
    };

    useEffect(() => {
        // Function to calculate cart count
        const calculateCartCount = () => {
            const cart = JSON.parse(secureLocalStorage.getItem('cart')) || [];
            return cart.length;
        };

        // Set cart count
        setCartCount(calculateCartCount());
    }, [cart]); // Empty dependency array means this runs once after the initial render

    

    useEffect(() => {
        document.documentElement.scrollTo({
          top: 0,
          left: 0,
          behavior: "instant", // Optional if you want to skip the scrolling animation
        });
      }, []);

    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    };

    const handleScroll = () => {
        if (window.scrollY > 250) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleLogoClick = () => {
        navigate(`${config.appUrl}`); // Navigate to the desired URL
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();
    };


    // Determine if a submenu item should be highlighted based on the current path
    const getActiveSubMenu = () => {
        const path = location.pathname;
        if (path.includes('self-paced')) return 'self-paced';
        if (path.includes('hands-on-wellness')) return 'hands-on-wellness';
        if (path.includes('online-wellness-services')) return 'online-wellness-services';
        if (path.includes('online-wellness-progams')) return 'online-wellness-progams';
        if (path.includes('products')) return 'products';
        if (path.includes('dosha-quiz')) return 'dosha-quiz';
        return '';
    };

    const activeSubMenu = getActiveSubMenu();

    return (
        <nav className={`navbar1 ${isScrolled ? 'navbar-scrolled' : ''}`}>
            <div className="navbar-nav1">
                <div className="menu-toggle pe-2" onClick={toggleMenu}>
                    {isMenuOpen ? (
                        <i className="bi bi-x text-white fs-2"></i> // Close icon when menu is open
                    ) : (
                        <i className="bi bi-list text-white fs-3"></i> // List icon when menu is closed
                    )}
                </div>
                <div className="navbar-logo">
                    <img src={Logo} alt="Logo" className='navbar-logo-img' onClick={handleLogoClick} />
                </div>
                <div className="navbar-right">
                    <ul className={`navbar-nav-menu ${isMenuOpen ? 'active' : ''}`}>
                        <li className="nav-item">
                            <Link to={`${config.appUrl}`} className="nav-link1">Home</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link1" data-toggle="dropdown" aria-expanded="true">
                                Services&nbsp;<i class="bi bi-caret-down-fill fs-6"></i>
                            </a>
                            <ul className={`dropdown-menu1 ${isMenuOpen ? 'active' : ''}`}>
                                <li>
                                    <Link 
                                        to={`${config.appUrl}hands-on-wellness`} 
                                        className={`dropdown-item1 ${activeSubMenu === 'hands-on-wellness' ? 'active' : ''}`}
                                    >
                                        Hands-On Wellness Service
                                    </Link>
                                </li>
                                <li>
                                    <Link 
                                        to={`${config.appUrl}online-wellness-services`} 
                                        className={`dropdown-item1 ${activeSubMenu === 'online-wellness-services' ? 'active' : ''}`}
                                    >
                                        Online Wellness Services
                                    </Link>
                                </li>
                                <li>
                                    <Link 
                                        to={`${config.appUrl}online-wellness-progams`} 
                                        className={`dropdown-item1 ${activeSubMenu === 'online-wellness-progams' ? 'active' : ''}`}
                                    >
                                        Online Wellness Programs
                                    </Link>
                                </li>
                                <li>
                                    <Link 
                                        to={`${config.appUrl}self-paced`} 
                                        className={`dropdown-item1 ${activeSubMenu === 'self-paced' ? 'active' : ''}`}
                                    >
                                        Self-Paced Learning
                                    </Link>
                                </li>
                                {/* <li>
                                    <Link 
                                        to={`${config.appUrl}live-course`} 
                                        className={`dropdown-item1 ${activeSubMenu === 'live-course' ? 'active' : ''}`}
                                    >
                                        Live Courses
                                    </Link>
                                </li> */}
                                
                                
                                <li>
                                    <Link 
                                        to={`${config.appUrl}products`} 
                                        className={`dropdown-item1 ${activeSubMenu === 'products' ? 'active' : ''}`}
                                    >
                                        Products
                                    </Link>
                                </li>
                                <li>
                                    <Link 
                                        to={`${config.appUrl}dosha-quiz`} 
                                        className={`dropdown-item1 ${activeSubMenu === 'dosha-quiz' ? 'active' : ''}`}
                                    >
                                        Dosha Quiz
                                    </Link>
                                </li>
                            </ul>
                        </li>


                        <li className="nav-item">
                            <Link to={`${config.appUrl}press-media`} className="nav-link1">Media Gallery</Link>
                        </li>

                        <li className="nav-item">
                            <Link to={`${config.appUrl}blogs`} className="nav-link1">Blog</Link>
                        </li>

                        <li className="nav-item dropdown">
                            <a className="nav-link1" data-toggle="dropdown" aria-expanded="true">
                               Our Journey&nbsp;<i class="bi bi-caret-down-fill fs-6"></i>
                            </a>
                            <ul className="dropdown-menu1">
                                <li><Link to={`${config.appUrl}about-us`} className="dropdown-item1">Our Story</Link></li>
                                <li><Link to={`${config.appUrl}our-team`} className="dropdown-item1">Our Team</Link></li>
                            </ul>
                        </li>
                        
                        <li className="nav-item">
                            <Link to={`${config.appUrl}contactus`} className="nav-link1">Contact</Link>
                        </li>
                    </ul>
                </div>
                <div className='navbar-end'>
                    <div className="navbar-Icons">
                        <div className="me-3 ms-3">
                            <i className="bi bi-search text-white fs-3" onClick={() => setIsSearchOpen(!isSearchOpen)}></i>
                        </div>
                        {isSearchOpen && (
                            <div className="search-container">
                                <input
                                    type="text"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    placeholder="Search for Courses and Blogs"
                                    className="search-input new"
                                />
                                {suggestions.courses && suggestions.blogs && (suggestions.courses.length > 0 || suggestions.blogs.length > 0) && (
                                    <div className="suggestions-dropdown">
                                        {suggestions.courses.length > 0 && (
                                            <div>
                                                <strong className='search-title'>Courses</strong>
                                                {suggestions.courses.map(course => (
                                                    <div key={course.id} className="suggestion-item " onClick={() => handleSuggestionClick(course.link)}>
                                                        {course.title}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        {suggestions.blogs.length > 0 && (
                                            <div className='pt-3'>
                                                <strong className='search-title'>Blogs</strong>
                                                {suggestions.blogs.map(blog => (
                                                    <div key={blog.id} className="suggestion-item" onClick={() => handleSuggestionClick(blog.link)}>
                                                        {blog.title}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                        <Link to={`${config.appUrl}cart`} className="me-2 px-2 position-relative cart-icon">
                            <i className="bi bi-cart3 text-white fs-3"></i>
                            <span className="cart-count fs-6">{cartCount}</span> 
                        </Link>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
