import { useEffect } from "react";
import Footer from "../../Components/Common/Footer/footer";
import Navbar from "../../Components/Common/Navbar/Navbar";
import Live1 from "../../Assets/Images/Services/Yoga.jfif"
import Live2 from "../../Assets/Images/Services/Long.jfif"
import Live3 from "../../Assets/Images/Services/Pranayana.png"
import Live4 from "../../Assets/Images/Services/Meditation.png"
import { useNavigate } from "react-router-dom";
import config from "../config";
import { Helmet } from "react-helmet";

const OnlineWellness = () => {

    const CourseContent = useNavigate()

    const courseDetails = [
        {
            key: "yoga-2",
            title: "Yoga Therapy Service",
            subtitle: "",
            description: "Experience the transformative power of personalized Yoga Therapy from the comfort of your home. Our online sessions are designed to address your unique physical, mental, and emotional needs, providing a customized approach to healing and recovery. Whether you're seeking stress relief, physical recovery, or emotional balance, our tailored practices support your health goals, allowing you to achieve well-being without leaving your space.",
            image: Live1,
        },
        {
            key: "long-distance-reiki",
            title: "Long-Distance Reiki Healing Service",
            subtitle: "",
            description: "Experience the profound healing energy of Reiki from the comfort of your own home. Our long-distance sessions are designed to balance your energy and promote healing at mental, physical, emotional, and karmic levels. As energy knows no boundaries, you can tap into the transformative benefits of Reiki to restore harmony and well-being, wherever you are.",
            image: Live2,
        },
        {
            key: "pranayama-breath-work",
            title: "Pranayama (Breath Work) Service",
            subtitle: "",
            description: "Awaken your body's energy centers and cultivate inner strength through the transformative power of Pranayama. From the comfort of your home, experience the therapeutic benefits of breathwork to boost vitality, balance your mind, and empower your healing journey. Each personalized session is tailored to your unique needs, guiding you toward greater well-being and inner harmony",
            image: Live3,
        },
        {
            key: "meditation-2",
            title: "Meditation Service",
            subtitle: "",
            description: "Experience calmness, wisdom, and serenity with our personalized meditation sessions, designed to help you become more efficient, productive, and energized throughout the day. From the comfort of your home, develop a positive mindset, gain clarity, and enhance your decision-making power through one-on-one guided meditation tailored to your unique needs.",
            image: Live4,
        },
    ];

    const handleReadMore = (name) => {
        CourseContent(`${config.appUrl}online-wellness/${name}`); // Navigate to /blog/:name route (only name in URL)
    };


    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return(
        <div className="Live Courses">
            <Helmet>
                <title>Visualization Healing for Wellbeing</title>
                <meta name="description" content="Empower your mind with visualization healing techniques that enhance wellness and inner calm." />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Visualization Healing for Wellbeing" />
                <meta property="og:description" content="Empower your mind with visualization healing techniques that enhance wellness and inner calm." />
                <meta property="og:url" content="https://artofselfhealing.ca/online-wellness-services/" />
                <meta name="twitter:title" content="Visualization Healing for Wellbeing" />
                <meta name="twitter:description" content="Empower your mind with visualization healing techniques that enhance wellness and inner calm." />
            </Helmet>
            <Navbar cart={1}/>
                <div className="Section-main">
                    <div className="live-course-header my-5">
                        <h1 className="text-white py-5 ps-2 ps-md-4 text-center">Online Wellness Services</h1>
                    
                    </div>

                    <div className="Service-grid ms-xl-3">
                        {courseDetails.map((course, index) => (
                            <div className=" hover-container ">
                                <img src={course.image} alt="Live1-image" className="w-100 object-fit-cover" />
                                    <h4 className="text-white mt-3 Main-Title">{course.title}</h4>
                                    {/* <h6 className="h6C text-white Main-Title Subtitle">{course.subtitle}</h6> */}
                                    <button className="card-work-button mt-4">See the Magic</button>
                                <div className="content text-start">
                                    <h3 className="text-white">{course.title}</h3>
                                    <h5 className="">{course.subtitle}</h5>
                                    <div className="d-flex">
                                            <h6 className="pt-1 ps-2 pb-1 h6C"> {course.description}</h6>
                                        </div>
                                    <button className="card-work-button" onClick={() => handleReadMore(course.key)}>Explore More<span><i className="bi bi-chevron-right"></i></span></button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            <Footer/>
        </div>
    )

}

export default OnlineWellness;