import Footer from "../../Components/Common/Footer/footer";
import Navbar from "../../Components/Common/Navbar/Navbar";
import Book from "../../Assets/Images/Book1.png";
import { useState } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";
import config from "../config";
import downloadfile from "../../Assets/Images/Sleep-Code_Shah-Kumar_ebook.pdf"

const Product_Detail = () => {
    const [showVariants, setShowVariants] = useState(false);
    const [cart,setcart] = useState();

    const handleOpenVariants = () => setShowVariants(true);

    const handlePaperBook = () => {
        window.open(config.paperbook, '_blank'); 
    }

    const handleAudiobook = () => {
        window.open(config.audiobook,'_blank')
    }

    return (
        <div>
            <Helmet>
                <title>Sleep Code E-book - artofselfhealing.ca</title>
                <meta name="description" content="Act now before it&#039;s gone: Limited time offer! Download Now Book by Pradeep Kumar &amp; Trupti ShahSleep CodeIf you&#039;re struggling to..Fall asleep at nightGet adequate sleep at nightReturn to sleep after having a nightmareWake up recharged and refreshedFocus &amp; remember thingsYou&#039;ll get a practical guideline to..Bring harmony in your lifeDevelop a peaceful &amp; happy mindDevelop" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Sleep Code E-book - artofselfhealing.ca" />
                <meta property="og:description" content="Act now before it&#039;s gone: Limited time offer! Download Now Book by Pradeep Kumar &amp; Trupti ShahSleep CodeIf you&#039;re struggling to..Fall asleep at nightGet adequate sleep at nightReturn to sleep after having a nightmareWake up recharged and refreshedFocus &amp; remember thingsYou&#039;ll get a practical guideline to..Bring harmony in your lifeDevelop a peaceful &amp; happy mindDevelop" />
                <meta property="og:url" content="https://artofselfhealing.ca/sleep-code-e-book/" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Sleep Code E-book - artofselfhealing.ca" />
                <meta name="twitter:description" content="Act now before it&#039;s gone: Limited time offer! Download Now Book by Pradeep Kumar &amp; Trupti ShahSleep CodeIf you&#039;re struggling to..Fall asleep at nightGet adequate sleep at nightReturn to sleep after having a nightmareWake up recharged and refreshedFocus &amp; remember thingsYou&#039;ll get a practical guideline to..Bring harmony in your lifeDevelop a peaceful &amp; happy mindDevelop" />
            </Helmet>
            <Navbar cart={cart}/>
            <div className="Section-main">
                <div className="row mt-md-5">
                    <div className="col-xl-4">
                        <img src={Book} alt="Book_Image" className="w-100 object-fit-cover" />
                    </div>
                    <div className="col-xl-8 Product_Data mt-4">
                        <h1 className="h1C text-start text-white my-1">Sleep Code: Renew Your Lifestyle, Recode Your Mind, Restore Your Sleep</h1>
                        <h4 className="h4C ps-3">By Pradeep Kumar and Trupti Shah</h4>
                        <h6 className="h6C text-white ps-3 pt-2">The Sleep Code was written to help us all attain better sleep by reintroducing ancient sleep approaches for the modern world. Pradeep and Trupti have taken on a holistic approach to sleep, wellness, and spirituality in order to effectively address sleep disorders—a silent yet chronic issue. Over the course of their 10 Sleep Codes, they have shared how to target the issue, and how to overcome it with mindset and lifestyle changes—all while unpacking thousands of years of Indian practice for the modern sleeper.</h6>
                        {!showVariants && (
                            <button className="card-work-button ms-3 mt-2" onClick={handleOpenVariants}>Buy Now</button>
                        )}
                        {showVariants && (
                            <div className="variant-selection mt-3 ms-3">
                                <h6 className="h6C text-white">Select a Variant to purchase:</h6>
                                <a className='card-work-button' style={{textDecoration:"none"}} href={downloadfile} download>Download free E-book</a>
                                <button className="card-work-button ms-xl-2 mt-3 mt-xl-0 me-2 me-xl-0" onClick={() => handlePaperBook()}>Buy Paperback</button>
                                <button className="card-work-button mt-2 ms-xl-2 mt-md-0" onClick={() => handleAudiobook()}>Buy Audiobook</button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <ToastContainer className="mt-5" position="top-right" autoClose={3000} hideProgressBar={false} />
            <Footer />
        </div>
    );
}

export default Product_Detail;
