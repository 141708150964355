import React, { useState } from 'react';
import Footer from "../Components/Common/Footer/footer";
import Navbar from "../Components/Common/Navbar/Navbar";
import Image from "../Assets/Images/Services/Automated-details-1.jpg";
import { Helmet } from 'react-helmet';
import image1 from "../Assets/Images/Vata.jfif"
import image2 from "../Assets/Images/Pitta.jfif"
import image3 from "../Assets/Images/Kapha.jfif"
import { notification } from 'antd';

const Dosha_quiz = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userDetails, setUserDetails] = useState({ name: '', email: '', contact: '' });
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [totalPoints, setTotalPoints] = useState(0);
    const [quizStarted, setQuizStarted] = useState(false);
    const [quizFinished, setQuizFinished] = useState(false); // New state for quiz finished

    const quizQuestions = [
        { question: "1) How is your Body weight?", options: ["Low", "Moderate", "Over Weight"] },
        { question: "2) How is your Pulse?", options: ["thready, fast moves like a snake", "moderate-jumping like frog", "broad, slow, moves like a swan"] },
        { question: "3) How is your skin?", options: ["dry, rough", "soft, oily", "thick, oily"] },
        { question: "4) How are your Lips?", options: ["thin, dry, cracked", "red moist", "pale, moist, big, large size"] },
        { question: "5) How is your Gait?", options: ["fast, short steps", "normal", "slow, graceful"] },
        { question: "6) How is your Appetite?", options: ["Irregular", "Strong", "Slow"] },
        { question: "7) How is your Elimination?", options: ["constipation", "loose", "Moderate"] },
        { question: "8) How is your Memory?", options: ["less, forgetful", "sharp", "slow, strong"] },
        { question: "9) How is your sleep?", options: ["restless, scanty, 5-7 hours a night", "alert, even, 6-8hrs a night", "prolonged hours, deep, difficult to wake up"] },
        { question: "10) How is your speech?", options: ["Fast Unclear", "Sharp", "slow monotonous"] },
        { question: "11) Your Favourite juice?", options: ["Sweet", "Salty", "Bitter"] },
        { question: "12) What is your Preferred taste?", options: ["salty, sour, sweet", "sweet, bitter, astringent", "pungent, bitter, astringent"] },
        { question: "13) How is your Emotional temperament?", options: ["fearful, insecure, unpredictable, moody, jealous ", "aggressive, irritable, anger, impatience, jealous", "calm, greedy, attached, depression, likable"] },
        { question: "14) How is your Thinking?", options: ["restless, quick", "organized, accurate at optimal speech", "slow, methodical"] },
        { question: "15) What do you Love to?", options: ["traveling, art, esoteric subjects", "sports, politics, luxury", "calm, complacent, get angry slowly"] },
    ];

    const [answers, setAnswers] = useState(Array(quizQuestions.length).fill(null));

    const handleStartQuiz = () => {
        setIsModalOpen(true);
    };

    const handleDetailsChange = (e) => {
        const { name, value } = e.target;
    
        // Format the phone number input
        if (name === 'contact') {
            const digits = value.replace(/\D/g, '');
            let formattedPhone = '';
            if (digits.length > 0) {
                formattedPhone += digits.substring(0, 3);
            }
            if (digits.length > 3) {
                formattedPhone += '-' + digits.substring(3, 6);
            }
            if (digits.length > 6) {
                formattedPhone += '-' + digits.substring(6, 10);
            }
            setUserDetails({ ...userDetails, [name]: formattedPhone });
        } else {
            setUserDetails({ ...userDetails, [name]: value });
        }
    };

    const handleSubmitDetails = (e) => {
        e.preventDefault();
        setQuizStarted(true);
        setCurrentQuestion(0);
        setTotalPoints(0);
    };

    const handleOptionChange = (questionIndex, optionIndex) => {
        const updatedAnswers = [...answers];
        updatedAnswers[questionIndex] = optionIndex; 
        setAnswers(updatedAnswers);

        const points = (optionIndex + 1); 
        const newTotalPoints = totalPoints + points;
        setTotalPoints(newTotalPoints);
    };

    const handlePrevQuestion = () => {
        if (currentQuestion > 0) {
            setCurrentQuestion(currentQuestion - 1);
        }
    };

    const handleNextQuestion = () => {
        if (answers[currentQuestion] !== null) {
            if (currentQuestion < quizQuestions.length - 1) {
                setCurrentQuestion(currentQuestion + 1);
            } else {
                setQuizFinished(true); // Set quiz as finished
            }
        } else {
            notification.open({
                message: "No Option Selected",
                description: "Please select an option before proceeding.",
            });
        }
    };

    const handleCloseModal = () => {
        if(quizFinished){
            window.location.reload();
        }
        setIsModalOpen(false);
    };

    return (
        <div className="Dosha_quiz">
            <Helmet>
                <title>Discover Your Dosha with Our Quiz - artofselfhealing.ca</title>
                <meta name="description" content="Take our Dosha Quiz to uncover your unique Ayurvedic profile for a balanced, healthy lifestyle." />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Discover Your Dosha with Our Quiz - artofselfhealing.ca" />
                <meta property="og:description" content="Take our Dosha Quiz to uncover your unique Ayurvedic profile for a balanced, healthy lifestyle." />
                <meta property="og:url" content="https://artofselfhealing.ca/dosha-quiz/" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Discover Your Dosha with Our Quiz - artofselfhealing.ca" />
                <meta name="twitter:description" content="Take our Dosha Quiz to uncover your unique Ayurvedic profile for a balanced, healthy lifestyle." />
            </Helmet>
            <Navbar cart={1} />
            <div className="Section-main">
                <div className="row mt-5 mx-xl-1 ">
                    <div className="col-xl-5 pe-0">
                        <img src={Image} alt="Dosha" className="w-100 object-fit-cover" />
                    </div>
                    <div className="col-xl-7 Course_data1 p-4">
                        <h2 className="h2C text-white mb-0">Dosha Quiz</h2>
                        <h4 className="h4C ps-0">Know Your Body Type</h4>
                        <h6 className="h6C text-white mt-4"><b>In Ayurveda, each of us has a unique mind-body type,</b> known as a dosha. Think of a dosha as your individual blueprint that describes your unique personality, tendencies, and physical nature. Understanding your dosha reveals how to keep yourself in balance to stay happy, healthy, and disease-free.</h6>
                        <h6 className="h6C text-white mt-3">By gathering information in this quiz, we’ll give you a breakdown of your specific dosha blend along with personalized practices for mind, body, and spirit well-being.</h6>
                        <h6 className="h6C text-white mt-3">Answer each question as objectively as possible, selecting the option that best describes yourself for most of your life, not necessarily in this moment or situationally. If you’re having a hard time doing so, try selecting the option that best describes someone similar to you.</h6>
                        <button className='card-work-button mt-3' onClick={handleStartQuiz}>Start Quiz</button>
                    </div>
                </div>

                {/* Modal */}
                {isModalOpen && (
                    <div className="modal-overlay quiz">
                        <div className="modal-content quiz" onClick={e => e.stopPropagation()}>
                            <div className='modal-controls mt-3'>
                                <span className='fs-3 text-white' onClick={handleCloseModal}><i className="bi bi-x-square-fill"></i></span>
                            </div>
                            {!quizStarted ? (
                                <div className="start-quiz-section">
                                    <h5 className="text-white mt-4">Before you start the quiz, please enter your details:</h5>
                                    <form onSubmit={handleSubmitDetails}>
                                        <input
                                            type="text"
                                            name="name"
                                            placeholder="Name"
                                            value={userDetails.name}
                                            onChange={handleDetailsChange}
                                            required
                                            className='Contact-form-input'
                                        />
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Email "
                                            value={userDetails.email}
                                            onChange={handleDetailsChange}
                                            required
                                            className='Contact-form-input'
                                            />
                                            <input
                                                type="contact"
                                                name="contact"
                                                placeholder="Contact No"
                                                value={userDetails.contact}
                                                onChange={handleDetailsChange}
                                                required
                                                className='Contact-form-input'
                                            />
                                            <button type="submit" className="card-work-button mt-3">Start</button>
                                        </form>
                                    </div>
                                ) : quizFinished ? (
                                    <div className="quiz-results-section text-center">
                                        {/* <h4 className="h4C ps-0">Quiz Results</h4> */}
                                        <h4 className="h4C text-white mt-2">Below is your Body Type</h4>
                                        {totalPoints >= 15 && totalPoints <= 22 && (
                                            <img className='result-image' src={image1} alt="Body Type for 15-22 points" />
                                        )}
                                        {totalPoints >= 23 && totalPoints <= 37 && (
                                            <img className='result-image' src={image2} alt="Body Type for 23-37 points" />
                                        )}
                                        {totalPoints >= 38 && totalPoints <= 45 && (
                                            <img className='result-image' src={image3} alt="Body Type for 38-45 points" />
                                        )}
                                        {/* <p className="h6C text-white mt-3">Total Points: {totalPoints}</p> */}
                                        {/* You can add more detailed results based on totalPoints here */}
                                    </div>
                                ) : (
                                    <div className="quiz-section">
                                        <h4 className="h4C ps-0">Question {currentQuestion + 1}</h4>
                                        <h5 className="h5C text-white mt-4">{quizQuestions[currentQuestion]?.question}</h5>
                                        <div className="options mt-3">
                                            {quizQuestions[currentQuestion]?.options.map((option, index) => (
                                                <div key={index} className="option">
                                                    <input
                                                        type="radio"
                                                        id={`q${currentQuestion}-o${index}`}
                                                        name={`quiz-${currentQuestion}`}
                                                        checked={answers[currentQuestion] === index}
                                                        onChange={() => handleOptionChange(currentQuestion, index)}
                                                    />
                                                    <label className='fs-5' htmlFor={`q${currentQuestion}-o${index}`}>{option}</label>
                                                </div>
                                            ))}
                                        </div>
                                        {currentQuestion > 0 && (
                                            <button onClick={handlePrevQuestion} className="card-work-button mt-2">
                                                Prev Question
                                            </button>
                                        )}
                                        <button onClick={handleNextQuestion} className="card-work-button mt-2 ms-xl-2">
                                            {currentQuestion < quizQuestions.length - 1 ? 'Next Question' : 'Finish Quiz'}
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <Footer />
            </div>
        );
    }
    
    export default Dosha_quiz;
    
