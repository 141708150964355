

// export const axiosInstance = axios.create({
//   baseURL: "http://devapi.myquest-test.com/v1",
// })

const config = {
  appUrl: "/",
  fullurl : "https://www.artofselfhealing.ca",
  paperbook : "https://www.amazon.ca/Sleep-Code-Lifestyle-Recode-Restore/dp/1777662826/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=1676902289&sr=8-1",
  audiobook : "https://www.amazon.ca/dp/B09YCRZPPM?ref_=cm_sw_r_apin_dp_7RVJRCXEMPNCM7PA35F0",
  facebook : "https://www.facebook.com/AOSHsleepcode/",
  instagram : "https://www.instagram.com/artofselfhealing.ca/",
  youtube : "https://www.youtube.com/@artofselfhealing6544",
  backendlink : "https://artofselfhealing.ca/application/public/api/",
  stripe_key : 'pk_live_51MaMThHGul1KtaR0Ys8639C4deRZ61cBOfuv2cY6ZzwzSVo3rin9sxOH1kf3uuVupKnzDjR6zADV6PIcjenTRRKk00lm9ySNfw'
}
// =============================

export const configEditor = {
  readonly: false,
  height: 400,
  askBeforePasteHTML: false,
  placeholder: "",
}



export default config
