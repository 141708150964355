import { useState } from "react";
import Footer from "../../Components/Common/Footer/footer";
import Navbar from "../../Components/Common/Navbar/Navbar";
import Course_image from "../../Assets/Images/Services/Automated-details-1.jpg"
import Mentor from "../../Assets/Images/Prapti.jpg"
import { useEffect } from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Image1 from "../../Assets/Images/Services/Aumated_Slider1.jpg"
import Image2 from "../../Assets/Images/Services/Aumated_Slider2.jpg"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Course_Image1 from "../../Assets/Images/Services/Automated-Services.jpeg"
import {Helmet} from "react-helmet";
import  secureLocalStorage  from  "react-secure-storage";

const AutomatedCourse = () => {

    const [showAutomatedData, setShowAutomatedData] = useState(false);
    const [cart,setcart] = useState();

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay:true,
        arrows:false,
        autoplaySpeed: 3000, 
      };
    
    const Ssettings = {
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay:true,
        arrows : false,
        autoplaySpeed: 30000,
        responsive: [
            {
                breakpoint: 500, // when the viewport width is 500px or less
                settings: {
                    slidesToShow: 1, // show only one slide at a time
                    slidesToScroll: 1, // scroll one slide at a time
                }
            }
        ]    
    }


    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const customToastStyle = {
        backgroundColor: '#333',
        color: '#fff',
        fontSize: '16px',
        borderRadius: '8px',
        margintop: '20px',
    };

    const handleGetCourseClick = () => {
        const cart = JSON.parse(secureLocalStorage.getItem('cart')) || [];
        
        // Create the item with price, image, and slug
        const item = {
            id : 7,
            slug: "Mastering-Effortless-Manifestation", // This is the slug extracted from the URL
            name : "Mastering Effortless Manifestation",
            price: "152.99",
            image: Course_image,
            quantity : 1,
        };
    
        // Check if the item already exists in the cart
        const isItemInCart = cart.some(cartItem => cartItem.slug === item.slug);
    
        if (isItemInCart) {
            // Item already in cart, show error toast
            toast.error("Course is already in the cart!", {
                style: customToastStyle,
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        } else {
            // Add new item to the cart
            cart.push(item);
            
            // Save updated cart back to local storage
            secureLocalStorage.setItem('cart', JSON.stringify(cart));
            
            // Show success toast
            toast.success("Course added to cart!", {
                style: customToastStyle,
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            setcart(1);
        }
    };

    
    return(
        <div className="Automated_Details">
            <Helmet>
                <title>Mastering Effortless Manifestation - artofselfhealing.ca</title>
                <meta name="description" content="Personal InstructorHi, I’m Prapti!Your Manifestation Guide Manifestation GuideReady for a Life Change? Previous slide Next slide Facebook-f Twitter Youtube Welcome to Your New Beginning! Have you ever felt like a spectator in your own life? If so, you're in the right place. Our Mastering Effortless Manifestation course is a welcoming haven for dreamers who are" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Mastering Effortless Manifestation - artofselfhealing.ca" />
                <meta property="og:description" content="Personal InstructorHi, I’m Prapti!Your Manifestation Guide Manifestation GuideReady for a Life Change? Previous slide Next slide Facebook-f Twitter Youtube Welcome to Your New Beginning! Have you ever felt like a spectator in your own life? If so, you&#039;re in the right place. Our Mastering Effortless Manifestation course is a welcoming haven for dreamers who are" />
                <meta property="og:url" content="https://artofselfhealing.ca/mastering-effortless-manifestation/" />
                <meta name="twitter:title" content="Mastering Effortless Manifestation - artofselfhealing.ca" />
        		<meta name="twitter:description" content="Personal InstructorHi, I’m Prapti!Your Manifestation Guide Manifestation GuideReady for a Life Change? Previous slide Next slide Facebook-f Twitter Youtube Welcome to Your New Beginning! Have you ever felt like a spectator in your own life? If so, you&#039;re in the right place. Our Mastering Effortless Manifestation course is a welcoming haven for dreamers who are" />
            </Helmet>
            <Navbar cart={cart}/>
            <div className="Section-main  text-white">
                <div className="Automated-main ps-md-3 pb-md-5 mt-md-5">
                    <h1 className="pt-5 ">Mastering Effortless Manifestation</h1>
                    <h4 className="pt-2 h4C ps-0">Ready for a Life Change?</h4>
                    <button className="card-work-button mt-4" onClick={handleGetCourseClick}>Buy Course&nbsp;<span><i className="bi bi-chevron-right"></i></span></button>
                </div>
                <div className="Slider-Main ms-xl-3 me-xl-3 mt-5">
                    <Slider {...settings}>
                    <div className="slide-item">
                        <img src={Image1} alt="Slide 1" />
                        <div className="Slider_overlay">
                        <div className='Slider_overlay-content1 pe-5'>
                            <h5 className='h5C text-end'>Personal Instructor</h5>
                            <h1 className='h1C text-end'>Hi , I'm Prapti! <br/> Your Manifastation Guide</h1>
                        </div>
                        </div>
                    </div>
                    <div className="slide-item">
                        <img src={Image2} alt="Slide 2" />
                        <div className="Slider_overlay"></div>
                        <div className='Slider_overlay-content1'>
                            <h5 className='h5C text-center'>Manifestation Guide</h5>
                            <h1 className='h1C'>Ready for <br/> a Life Change?</h1>
                        </div>
                    </div>
                    </Slider>
                </div>
                <div className="Automated_data row mt-5 ms-xl-3 me-xl-1">
                    <div className="col-12 col-xl-7 pt-xl-5 pb-xl-3 ps-xl-4">
                        {/* <h1 className="h1C text-start">Welcome to Your New Beginning!</h1> */}
                        <h4 className="h4C ps-0">Have you ever felt like a spectator in your own life? </h4>
                        <h6 className="h6C text-white pt-3">If so, you’re in the right place. Our Mastering Effortless Manifestation program is a welcoming haven for dreamers ready to step into their power. Whether you’re just beginning your journey or seeking to unlock your full potential, this course will guide you toward manifesting your deepest desires.</h6>
                        <h6 className="h6C text-white pt-3">Manifestation is the art of turning your dreams and goals into reality by harnessing the power of your thoughts, emotions, and energy. Our Mastering Effortless Manifestation Program is designed to guide you through this transformative process, helping you explore your inner self and align your intentions with your actions. Through expert guidance, you'll learn how to effortlessly manifest your desires, whether it’s achieving personal growth, career success, or emotional fulfillment.</h6>
                        <h4 className="h4C text-white pt-3">How It Benefits You</h4>
                        <h6 className="h6C text-white pt-3">Manifestation gives you direct control over your life, allowing you to shape your reality with clarity and purpose. By learning how to consciously manifest, you can break through mental barriers, take command of your destiny, and create lasting success. This practice also fosters humility and strength as you uncover deeper truths about yourself and the universe, empowering you to live a more fulfilled and aligned life.</h6>
                        <button className="card-work-button mt-3 m-2" onClick={() => setShowAutomatedData(!showAutomatedData)} style={{ display: !showAutomatedData ? 'block' : 'none' }}>
                            Find Out More<i className={`bi bi-caret-down-fill ps-2`}></i>
                        </button>
                    </div>
                    <div className="col-12 col-xl-5 p-3">
                        <img src={Course_image} alt="Automated Course" className="w-100 h-100 pe-0 object-fit-cover"/>
                    </div>
                </div>
               
                <div className=" row pt-5 me-xl-1 ms-xl-3" style={{ display: showAutomatedData ? 'flex' : 'none' }}>
                    <div className="col-12 col-xl-12 Automated_data1">
                        <h1 className="h1C text-start">How It Works</h1>
                        <div className="row">
                            <div className="col-12 Why-Section1">
                                <h5 className="h4C text-white">Our program one-on-one* or group class during one day of intensive training offers astep-by-step approach to understanding the core principles of manifestation. You will:</h5>
                                <ul>
                                <li><h5 className="h4C mt-xl-4">Master the logic and techniques behind manifestation.</h5></li>
                                <li><h5 className="h4C">Learn how to visualize your dreams and goals with precision</h5></li>
                                <li><h5 className="h4C">Break down mental blocks that hinder your progress.</h5></li>
                                <li><h5 className="h4C">Develop strategies to implement daily actions that turn aspirations into reality.</h5></li>
                                <li><h5 className="h4C">Continue expanding your success and growth long after the program ends.</h5></li>
                                </ul>
                                <h5 className="h4C pt-3 ps-3">By the end of the course, you will have the tools, techniques, and mindset needed to manifest your dreams effortlessly, creating a life of purpose and abundance. All of this can be achieved from the comfort of your own space, through our personalized one-on-one online sessions.</h5>
                                <h5 className="h4C text-white ps-3">Are you ready to take control of your future and bring your dreams into reality? Start your journey today!</h5>
                            </div>
                        </div>
                        <button className="card-work-button mt-3 m-2" onClick={() => setShowAutomatedData(!showAutomatedData)} style={{ display: showAutomatedData ? 'block' : 'none' }}>
                                Hide Details<i className={`bi bi-caret-up-fill ps-2`}></i>
                            </button>
                    </div>
                </div>

               {/* Mentor Details */}
                    <div className="row Mentor-Data mt-3 me-xl-1 ms-xl-3">
                        <div className="col-12 col-xl-4">
                            <img src={Mentor} alt="Mentor" className="pt-3 pt-xl-0 w-100 h-100 object-fit-cover"/>
                        </div>
                        <div className="col-12 col-xl-8 ps-2 pt-5 pe-xl-5 Mentor-Details">
                            <h4 className="h4C ps-0 pt-4 pt-xl-0">Meet Your Mentor</h4>
                            <h1>Prapti ShahKumar</h1>
                            <h6 className="pt-3 h6C text-white">Hello and welcome! I’m <b> Prapti </b>, and I’ll be guiding you through the transformative journey we’re about to embark on together. This course, Mastering Effortless Manifestation, was developed by my mother, Trupti Shah. Following in her footsteps, I have experienced firsthand the incredible power of manifestation. Now, I’m here to share these life-changing techniques with you. Let me share a little bit about myself before we dive in.</h6>
                        
                            <h4 className="pt-3 text-end">Professional Background:</h4>
                            <h6 className="pt-2 h6C text-white text-end ">By day, I manage projects in the fast-paced world of digital marketing. This has taught me how to set clear goals, stay focused, and overcome challenges—skills that are essential for mastering manifestation. But when evening comes, I switch gears completely, diving into the calming world of energy healing.</h6>
                        
                            <h4 className="pt-3">Heritage and Passion:</h4>
                            <h6 className="pt-2 h6C text-white ">Energy healing isn’t just a hobby for me; it’s part of my heritage. My parents founded “Art of Self Healing,” and growing up, healing was as normal as making a morning cup of coffee. Imagine a home where wellness practices were part of daily life—that was mine. This unique upbringing gave me a deep appreciation for holistic well-being.</h6>
                            
                            <h4 className="text-end mt-3">Experience and Expertise:</h4>
                            <h6 className="pt-2 h6C text-white text-end">I’ve been on this amazing path for many years, exploring and mastering the art of manifestation and energy healing. My journey has been one of continuous learning and growth, allowing me to gather a wealth of effective tools, techniques, and strategies. These practices have not only helped me build my ideal life from scratch but have also enabled me to guide others in crafting their dream lives.</h6>
                        
                            <h4 className="pt-3 ">Teaching Philosophy:</h4>
                            <h6 className="pt-2 h6C text-white ">My goal is to sprinkle a bit of transformation into your daily life, making each moment a step towards healing and self-fulfillment. I believe that everyone has the potential to manifest their deepest desires and achieve true happiness. Through this course, I am here to walk you through every step, providing personalized guidance and support.</h6>
                            <h6 className="pt-2 h6C text-white  ">Join me on this transformative journey, and let’s work together to bring positive change and fulfillment into your life. I am excited to share my knowledge and help you unlock your true potential.</h6>
                        </div>
                        
                    </div>

                <div className="Success-Story1 mt-5">
                    <h1 className="h1C">Success Stories</h1>
                    <div className="Slider-Main ms-xl-3">
                        <Slider {...Ssettings}>
                        <div className="slide-item">
                            <div className="slide-content Success d-flex justify-content-center">
                                <div className="p-3 p-xl-4">
                                    <h5 className="h5C lh-base"><i class="bi bi-quote"></i> I’m excited to share my transformative experience with manifestation mentors Trupti Shah and Prapti Shahkumar, whose guidance in manifestation has profoundly changed my life. Before working with them, I was skeptical about the concept of manifestation and its potential impact.<br/>However, their approach and wisdom opened my eyes and empowered me to achieve incredible results. <i class="bi bi-quote"></i></h5>
                                    <h4 className="h4C lh-base  mt-3">- Cobie Gregory</h4>
                                </div>
                            </div>                    
                        </div>
                        <div className="slide-item">
                            <div className="slide-content Success d-flex justify-content-center">
                                <div className="p-4">
                                    <h5 className="h5C lh-base text-white"><i class="bi bi-quote"></i>  Trupti Shah and Prapti Shahkumar are extraordinary manifestation coaches with the power to change lives. Their expertise, personalized approach, and unwavering support have helped me unlock my potential and manifest the life I always dreamed of. They are among the best mentors I have found. If you are ready to transform your life and achieve your deepest desires, I wholeheartedly recommend them as your guides and mentors. <i class="bi bi-quote"></i> </h5>
                                    <h4 className="h4C lh-base text-white mt-3">- Taylor Moore</h4>
                                </div>
                            </div>
                            
                        </div>
                        </Slider>
                    </div>
                </div>
            </div>
            <div className='blog-end Automated p-0 course d-xl-flex justify-content-between align-items-center'>
                <div className='blog-end-div Automated d-flex justify-content-center m-0 ps-xl-3'>
                    <h2 className='h2C Automated-CTA-Text ps-2 ps-xl-4 ms-xl-5'>Ready For a Life Change</h2>
                </div>
                <div className='blog-end-div Automated2 m-0 pe-md-5 d-flex'>
                    <button className='card-work-button me-md-5' onClick={() => handleGetCourseClick()}>
                        Get the Course<i className="bi bi-chevron-right"></i>
                    </button>
                </div>
            </div>
            <ToastContainer className="mt-5" position="top-right" autoClose={3000} hideProgressBar={false} />
            <Footer/>
        </div>
    )

}

export default AutomatedCourse;