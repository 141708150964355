import { useState } from 'react';
import mind from '../../Assets/Images/mind.png';
import yoga from '../../Assets/Images/yoga.png';
import sleep from '../../Assets/Images/sleep.png';

const Section3 = () => {
    const [selectedOption, setSelectedOption] = useState(1);

    const options = [
        { 
            id: 1, 
            text: 'Align Mind, Body, and Soul', 
            details: 'Experience profound healing by aligning your mind, body, and soul, as our holistic approach uncovers the root causes of illness and guides you toward vibrant wellness through natural,non-invasive methods.',
            image: mind,
        },
        { 
            id: 2, 
            text: 'Self-Healing Strategies', 
            details: 'Transform your life holistically with our self-healing strategies that harmonize emotional, mental, physical, social, spiritual, and financial dimensions, empowering you to achieve your deepest aspirations and embrace a life of balance, fulfillment, and vibrant success.',
            image: yoga,
        },
        { 
            id: 3, 
            text: 'Restful Sleep Solution', 
            details: 'Unlock the key to consistently restful sleep with our expertly crafted solutions, ensuring you wake up each morning feeling refreshed, rejuvenated, and ready to embrace the day with renewed energy and vibrant clarity.',
            image: sleep,
        },
    ];

    const handleOptionClick = (id) => {
        setSelectedOption(id);
    };

    const selectedOptionData = options.find((o) => o.id === selectedOption);

    return (
        <div className='Section3'>
            <div className='Homepage-Section'>
                <div className='row'>
                    <div className='col-12 col-xl-12 T'>
                        <div className='Secion3-content'>
                            <h1 className='h1C text-start text-white'>The Groundwork Of All Happiness Is Health</h1>
                            <h4 className='h4C'>We Are Here to Help.</h4>
                        </div>
                        <div className="container3">
                            <div className="options3">
                                {options.map((option) => (
                                    <div
                                        key={option.id}
                                        className={`option ${selectedOption === option.id ? 'active' : ''}`}
                                        onClick={() => handleOptionClick(option.id)}
                                    >
                                        <h5 className=" pb-1 h5C"><span>&#x2022;</span>&nbsp;{option.text}</h5>
                                    </div>
                                ))}
                            </div>
                            <div className="details3">
                                {selectedOption && (
                                    <div className="card-work">
                                        <div className="card-image">
                                            <img src={selectedOptionData.image} alt={selectedOptionData.text} style={{ height: '50px', width: '50px', objectFit: 'cover' }} />
                                        </div>
                                        <div className="card-text">
                                            <h5 className='Section3-h5 fw-bold'>{selectedOptionData.text}</h5>
                                            <p className='Section3-p fw-bold lh-base'>{selectedOptionData.details}</p>
                                        </div>
                                        {/* <button className='card-work-button'>Explore More&nbsp;<span><i className="bi bi-chevron-right"></i></span></button> */}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Section3;
