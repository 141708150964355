import { useNavigate } from "react-router-dom";
import config from "../../Pages/config";
import img3 from "../../Assets/Images/Services/Automated-details-1.jpg"


const Self_paced = () => {

    const Navigate = useNavigate();

    return(
        <div>
            <div className="Homepage-Section">
                <div className="Main">
                    <h1 className="ps-2 text-white mb-2">Self-Paced Learning</h1>
                    <h4 className="h4C lh-base my-3">Experience the freedom of self-paced learning by accessing pre-recorded sessions anytime, anywhere, allowing you to progress at your own speed and revisit material as needed. This flexible format ensures you can fully engage with each technique and tailor your learning to fit your schedule.</h4>
                </div>
                <div className="Col-2 ps-xl-1">
                    <div className="Cources">
                        <div className="card1 course new" >
                            <img src={img3} alt="Course1" className="Course-Image new" />
                            <h4 className="Section9-h4">
                                Mastering Effortless Manifestation Program
                            </h4>
                            <p className="Section9-p">Unlock your potential and effortlessly bring your desires to life with our self-paced Mastering Effortless Manifestation Program. Access expertly designed pre-recorded sessions from the comfort of your own space and learn transformative techniques to align your mind and energy for optimal results. Empower yourself to manifest your dreams and create the life you envision on your own schedule.<br/></p>
                        </div>
                    </div>
                    <div className=' mt-3 ms-xl-5 ps-xl-5'>
                        <button className='card-work-button' onClick={() => Navigate(`${config.appUrl}self-paced`)}>Unlock Self Paced Learning&nbsp;<span><i className="bi bi-chevron-right"></i></span></button>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Self_paced;