import "./Assets/Custom.css"
import Homepage from './Pages/Homepage';
import { Routes,Route , Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import BlogPage from './Pages/Blog';
import Contact from './Pages/Contact';
import AboutUs from './Pages/Aboutus';
import config from './Pages/config';
import OurTeam from "./Pages/Ourteam";
import AutomatedCourse from "./Pages/Services/Automated";
import LocalCourses from "./Pages/Services/Local";
import Products from "./Pages/Services/Products";
import BlogDetail from "./Pages/blog_details";
import Course_detail2 from "./Pages/Course_Details2";
import Press from "./Pages/Press-Media";
import Reiki_detail from "./Pages/Reiki_Service_Detail";
import Product_Detail from "./Pages/Services/Product_Detail";
import Cart from "./Pages/Cart";
import Checkout from "./Pages/Checkout";
import Dosha_quiz from "./Pages/Dosha_Quiz";
import NotFound from "./Pages/404Page";
import ConsultationCall from "./Pages/Services/Consultation";
import OnlineWellness from "./Pages/Services/online_wellness_service";
import OnlineWellnessProgram from "./Pages/Services/online-wellness-programs";
import SelfPaced from "./Pages/Services/Self-paced";
import ReikiCertification from "./Pages/Reiki_Certification";
import PaymentSuccess from "./Pages/Success";
import PaymentCancel from "./Pages/Cancel";
import Privacy from "./Pages/Privacypolicy";
import Terms from "./Pages/TermsConditions";

function App() {

  return (
    <div className="App">
      <Routes>
        {/* Redirect URLS */}
        <Route path={`${config.appUrl}manifestation-program/`} element={<Navigate to={`${config.appUrl}live/Manifestation-Program`} replace />} />
        <Route path={`${config.appUrl}mastering-deep-sleep/`} element={<Navigate to={`${config.appUrl}live/mastering-deep-sleep`} replace />} />
        <Route path={`${config.appUrl}usui-reiki-level-3-course/`} element={<Navigate to={`${config.appUrl}live/usui-reiki-level-3-course`} replace />} />
        <Route path={`${config.appUrl}usui-reiki-level-2-certification/`} element={<Navigate to={`${config.appUrl}live/usui-reiki-level-2-certification`} replace />} />
        <Route path={`${config.appUrl}crystal-reiki-master-certification/`} element={<Navigate to={`${config.appUrl}live/crystal-reiki-master-certification`} replace />} />
        <Route path={`${config.appUrl}reiki-certification/`} element={<Navigate to={`${config.appUrl}live/reiki-certification`} replace />} />
        <Route path={`${config.appUrl}meditation-2/`} element={<Navigate to={`${config.appUrl}online-wellness/meditation-2`} replace />} />
        <Route path={`${config.appUrl}massage-therapy-non-rmt-massages//`} element={<Navigate to={`${config.appUrl}local/massage-therapy-non-rmt-massages/`} replace />} />
        <Route path={`${config.appUrl}yoga-2`} element={<Navigate to={`${config.appUrl}online-wellness/yoga-2`} replace />} />
        <Route path={`${config.appUrl}pranayama-breath-work`} element={<Navigate to={`${config.appUrl}online-wellness/pranayama-breath-work`} replace />} />
        <Route path={`${config.appUrl}reiki-services`} element={<Navigate to={`${config.appUrl}local/reiki-services`} replace />} />
        <Route path={`${config.appUrl}long-distance-reiki/`} element={<Navigate to={`${config.appUrl}online-wellness/long-distance-reiki`} replace />} />

        <Route path={`${config.appUrl}ways-to-de-stress-finding-peace-in-a-busy-world`} element={<Navigate to={`${config.appUrl}blog/ways-to-de-stress-finding-peace-in-a-busy-world`} replace />} />
        <Route path={`${config.appUrl}how-to-consciously-eat-according-to-ayurveda/`} element={<Navigate to={`${config.appUrl}blog/how-to-consciously-eat-according-to-ayurveda`} replace />} />
        <Route path={`${config.appUrl}money-yantras-symbolism-of-sacred-geometry-in-spirituality/`} element={<Navigate to={`${config.appUrl}blog/money-yantras-symbolism-of-sacred-geometry-in-spirituality`} replace />} />
        <Route path={`${config.appUrl}harmonizing-body-mind-and-spirit/`} element={<Navigate to={`${config.appUrl}blog/harmonizing-body-mind-and-spirit`} replace />} />
        <Route path={`${config.appUrl}unifying-your-life/`} element={<Navigate to={`${config.appUrl}blog/unifying-your-life/`} replace />} />
        <Route path={`${config.appUrl}crafting-your-core-a-guide-to-cultivating-beliefs-values-and-a-resilient-worldview/`} element={<Navigate to={`${config.appUrl}blog/crafting-your-core-a-guide-to-cultivating-beliefs-values-and-a-resilient-worldview/`} replace />} />
        <Route path={`${config.appUrl}the-science-and-art-of-core-strengthening-exercises-building-a-strong-foundation-for-health/`} element={<Navigate to={`${config.appUrl}blog/the-science-and-art-of-core-strengthening-exercises-building-a-strong-foundation-for-health/`} replace />} />
        <Route path={`${config.appUrl}unleashing-your-brilliance-sharing-your-creative-gifts-through-mental-stimulation/`} element={<Navigate to={`${config.appUrl}blog/unleashing-your-brilliance-sharing-your-creative-gifts-through-mental-stimulation/`} replace />} />
        <Route path={`${config.appUrl}navigating-the-storm/`} element={<Navigate to={`${config.appUrl}blog/guardians-of-health-practical-tips-for-strengthening-your-immune-system`} replace />} />
        <Route path={`${config.appUrl}sacred-geometry-and-indian-spirituality-a-symphony-of-cosmic-patterns/`} element={<Navigate to={`${config.appUrl}blog/sacred-geometry-and-indian-spirituality-a-symphony-of-cosmic-patterns/`} replace />} />
        <Route path={`${config.appUrl}worklife-harmony-enriching-life-by-integrating-passion-purpose-and-play/`} element={<Navigate to={`${config.appUrl}blog/worklife-harmony-enriching-life-by-integrating-passion-purpose-and-play`} replace />} />
        <Route path={`${config.appUrl}natural-healing-methods-to-boost-your-immune-system/`} element={<Navigate to={`${config.appUrl}blog/natural-healing-methods-to-boost-your-immune-system`} replace />} />
        <Route path={`${config.appUrl}full-circle-wellness-the-synergy-of-exercise-nutrition-and-holistic-self-ca/`} element={<Navigate to={`${config.appUrl}blog/full-circle-wellness-the-synergy-of-exercise-nutrition-and-holistic-self-care`} replace />} />
        <Route path={`${config.appUrl}visualization-healing/`} element={<Navigate to={`${config.appUrl}blog/visualization-healing`} replace />} />
        <Route path={`${config.appUrl}conquering-emotions/`} element={<Navigate to={`${config.appUrl}blog/conquering-emotions`} replace />} />
        <Route path={`${config.appUrl}the-science-behind-holistic-healing-techniques/`} element={<Navigate to={`${config.appUrl}blog/the-science-behind-holistic-healing-techniques`} replace />} />
        <Route path={`${config.appUrl}making-an-impact-empowering-steps-to-enrich-your-environment-and-community/`} element={<Navigate to={`${config.appUrl}blog/making-an-impact-empowering-steps-to-enrich-your-environment-and-community`} replace />} />

        <Route path={`${config.appUrl}`} element={<Homepage/>}/>
        <Route path={`${config.appUrl}blogs`} element={<BlogPage/>}/>
        <Route path={`${config.appUrl}contactUs`} element={<Contact/>}/>
        <Route path={`${config.appUrl}about-us`} element={<AboutUs/>}/>
        <Route path={`${config.appUrl}our-team`} element={<OurTeam/>}/>
        <Route path={`${config.appUrl}mastering-effortless-manifestation`} element={<AutomatedCourse/>}/>
        <Route path={`${config.appUrl}hands-on-wellness`} element={<LocalCourses/>}/>
        <Route path={`${config.appUrl}online-wellness-services`} element={<OnlineWellness/>}/>
        <Route path={`${config.appUrl}online-wellness-progams`} element={<OnlineWellnessProgram/>}/>
        <Route path={`${config.appUrl}self-paced`} element={<SelfPaced/>}/>
        <Route path={`${config.appUrl}reiki-certification1`} element={<ReikiCertification/>}/>
        <Route path={`${config.appUrl}products`} element={<Products/>}/>
        <Route path={`${config.appUrl}blog/:title`} element={<BlogDetail/>} />
        <Route path={`${config.appUrl}live/:title`} element={<Course_detail2/>} />
        <Route path={`${config.appUrl}online-wellness/:title`} element={<Reiki_detail/>} />
        <Route path={`${config.appUrl}local/:title`} element={<Reiki_detail/>} />
        <Route path={`${config.appUrl}reiki/:title`} element={<Reiki_detail/>} />
        <Route path={`${config.appUrl}press-media`} element={<Press/>}/>
        <Route path={`${config.appUrl}sleep-code-e-book/`} element={<Product_Detail/>}/>
        <Route path={`${config.appUrl}cart`} element={<Cart/>}/>
        <Route path={`${config.appUrl}checkout`} element={<Checkout/>}/>
        <Route path={`${config.appUrl}dosha-quiz`} element={<Dosha_quiz/>}/>
        <Route path="*" element={<NotFound />} />
        <Route path={`${config.appUrl}consultation`} element={<ConsultationCall/>}/>
        <Route path={`${config.appUrl}success`} element={<PaymentSuccess />} />
        <Route path={`${config.appUrl}cancel`} element={<PaymentCancel/>}/>
        <Route path={`${config.appUrl}privacy-policy`} element={<Privacy/>}/>
        <Route path={`${config.appUrl}terms-condition`} element={<Terms/>}/>
      </Routes>

    </div>
  );
}

export default App;
