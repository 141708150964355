import { useEffect } from "react";
import Footer from "../../Components/Common/Footer/footer";
import Navbar from "../../Components/Common/Navbar/Navbar";
import Live1 from "../../Assets/Images/Services/Reiki_Certification.png"
import Live2 from "../../Assets/Images/Services/Crystal.png"
import Live3 from "../../Assets/Images/Services/Automated-details-1.jpg"
import Live4 from "../../Assets/Images/Services/Long.jfif"
import { useNavigate } from "react-router-dom";
import config from "../config";
import { Helmet } from "react-helmet";

const OnlineWellnessProgram = () => {

    const CourseContent = useNavigate()

    const courseDetails = [
        {
            key: "Reiki-Certification1",
            title: "Reiki Certification",
            subtitle: "",
            description: "Embark on your Reiki journey from the comfort of your home with our online Reiki Certification Program for Levels 1, 2, and 3. Gain the knowledge and skills to channel healing energy, empowering you to heal yourself and others effectively. Whether you're a beginner or seeking mastery, our comprehensive training provides the tools for personal growth and deep healing.",
            image: Live1,
        },
        {
            key: "Crystal-Reiki-Master-Certification",
            title: "Crystal Reiki Master Certification",
            subtitle: "",
            description: "Elevate your healing journey from the comfort of your own space with our Crystal Reiki Master Certification. This advanced online program integrates the transformative energies of crystals with Reiki, empowering you to deepen your skills and enhance your healing practice for yourself and others.",
            image: Live2,
        },
        {
            key: "Manifestation-Program",
            title: "Mastering Effortless Manifestation Program",
            subtitle: "",
            description: "Unlock your full potential and effortlessly bring your desires to fruition with our transformative Effortless Manifestation Program. From the comfort of your own space, embark on a journey of self-discovery and growth as you master powerful techniques to align your mind and energy. Our expert-guided program will empower you to manifest your dreams with clarity and ease, helping you create the life you’ve always envisioned. Start your path to success today and experience the profound impact of effortless manifestation.",
            image: Live3,
        },
        {
            key: "mastering-deep-sleep",
            title: "Mastering Deep Sleep Program",
            subtitle: "",
            description: "Revolutionize your sleep quality with our expertly designed Mastering Deep Sleep Program, accessible from the comfort of your own space. Our comprehensive program offers proven techniques and practices to cultivate restorative, deep sleep, helping you achieve the relaxation and rejuvenation your body craves. Experience the transformative power of sound sleep and wake up each day feeling refreshed and energized. Join us to unlock the secrets of deep sleep and elevate your well-being to new heights.",
            image: Live4,
        },
    ];

    const handleReadMore = (name) => {
        if(name == "Reiki-Certification1"){
            CourseContent(`${config.appUrl}reiki-certification1`);
        }else{
            CourseContent(`${config.appUrl}live/${name}`); // Navigate to /blog/:name route (only name in URL)
        }
        
    };


    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return(
        <div className="Live Courses">
            <Helmet>
                <title>Release Trapped Emotions Therapy - artofselfhealing.ca</title>
                <meta name="description" content="Free yourself from emotional blocks with our Release Trapped Emotions Therapy program." />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Release Trapped Emotions Therapy - artofselfhealing.ca" />
                <meta property="og:description" content="Free yourself from emotional blocks with our Release Trapped Emotions Therapy program." />
                <meta property="og:url" content="https://artofselfhealing.ca/online-wellness-progams/" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Release Trapped Emotions Therapy - artofselfhealing.ca" />
        		<meta name="twitter:description" content="Free yourself from emotional blocks with our Release Trapped Emotions Therapy program." />
            </Helmet>
            <Navbar cart={1}/>
                <div className="Section-main">
                    <div className="live-course-header my-5">
                        <h1 className="text-white py-5 ps-2 ps-md-4 text-center">Online Wellness Programs & Certification</h1>
                        
                    </div>

                    <div className="Service-grid ms-xl-3">
                        {courseDetails.map((course, index) => (
                        <div className=" hover-container">
                                <img src={course.image} alt="Live1-image" className="w-100 object-fit-cover" />
                                    <h4 className="text-white mt-3 Main-Title">{course.title}</h4>
                                    {/* <h6 className="h6C text-white Main-Title Subtitle">{course.subtitle}</h6> */}
                                    <button className="card-work-button mt-4">See the Magic</button>
                                <div className="content text-start">
                                    <h3 className="text-white">{course.title}</h3>
                                    <h5 className="">{course.subtitle}</h5>
                                    <div className="d-flex">
                                            <h6 className="pt-1 ps-2 pb-1 h6C"> {course.description}</h6>
                                        </div>
                                    {course.description2 && (
                                        <div className="d-flex">
                                            <span style={{fontSize:"30px"}}>&#x2022;</span>
                                            <h6 className="pt-1 ps-2 pb-1 h6C"> {course.description2}</h6>
                                        </div>
                                    )}
                                    {course.description3 && (
                                        <div className="d-flex">
                                            <span style={{fontSize:"30px"}}>&#x2022;</span>
                                            <h6 className="pt-1 ps-2 pb-1 h6C"> {course.description3}</h6>
                                        </div>
                                    )}
                                    <button className="card-work-button" onClick={() => handleReadMore(course.key)}>Explore More<span><i className="bi bi-chevron-right"></i></span></button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            <Footer/>
        </div>
    )

}

export default OnlineWellnessProgram;