import course1 from "../../Assets/Images/course2.png"
import course2 from "../../Assets/Images/Services/Massage.png"
import { useNavigate } from 'react-router-dom'
import config from '../../Pages/config'

const Programs = () =>{

    const Navigate = useNavigate();

    return(
        <div className='Section6'>
            <div className='Homepage-Section'>
                <div className='row'>
                    <div className='col-12 col-xl-12'>
                        <div className='Main-section'>
                            
                            <h1 className='Section6-h1 ps-3 ps-xl-0 mb-2'>
                                Hands-On Wellness Services at Our Location
                            </h1>
                        </div>
                        <h4 className="h4C lh-base my-3">
                            Experience the transformative power of personalized healing with our hands-on wellness services, offering an immersive, in-person journey at our center to help you achieve balance, relaxation, and renewal.
                        </h4>
                        <div className='row ps-xl-3 mt-xl-4 justify-content-center'>
                            <div className='col-12 col-xl-4'>
                                <div className="card1 course test" onClick={() => Navigate(`${config.appUrl}local/reiki-services`)}>
                                    <img src={course1} alt="Mind" className="Course-Image"></img>
                                    <h4 className='Section9-h4 pt-0'>Reiki Healing Service</h4>
                                    <p className='Section9-p mt-0'>Experience profound balance and rejuvenation with our personalized Reiki healing sessions, designed to reduce stress, enhance well-being, and reconnect you with your inner peace through transformative, community-focused care.</p>
                                </div>
                            </div>
                            <div className='col-12 col-xl-4'>
                                <div className="card1 course test" onClick={() => Navigate(`${config.appUrl}local/massage-therapy-non-rmt-massages`)}>
                                        <img src={course2} alt="Mind" className="Course-Image"></img>
                                    <h4 className='Section9-h4 pt-0'>Healing Massage</h4>
                                    <p className='Section9-p mt-0'>Embark on a rejuvenating journey with our transformative healing massage, where Ayurveda, Reiki, and Acupressure blend seamlessly to balance your mind, body, and spirit, using personalized techniques to reduce stress and promote deep relaxation.</p>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center mt-3'>
                            <button className='card-work-button' onClick={() => Navigate(`${config.appUrl}hands-on-wellness`)}>Unlock Hands-on Wellness Services&nbsp;<span><i className="bi bi-chevron-right"></i></span></button>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Programs;