import Footer from "../Components/Common/Footer/footer";
import Navbar from "../Components/Common/Navbar/Navbar";
import User from "../Assets/Images/Press7.png"
import User2 from "../Assets/Images/Trupti.jpg"
import User3 from "../Assets/Images/Prapti.jpg"
import { Helmet } from "react-helmet";

const OurTeam = () => {
    return(
        <div className="Ourteam">
            <Helmet>
                <title>Meet Our Healing Team - artofselfhealing.ca</title>
                <meta name="description" content="Meet our dedicated wellness experts committed to your holistic health journey." />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Meet Our Healing Team - artofselfhealing.ca" />
                <meta property="og:description" content="Meet our dedicated wellness experts committed to your holistic health journey." />
                <meta property="og:url" content="https://artofselfhealing.ca/our-team/" />
                <meta name="twitter:card" content="summary_large_image" />
		        <meta name="twitter:title" content="Meet Our Healing Team - artofselfhealing.ca" />
                <meta name="twitter:description" content="Meet our dedicated wellness experts committed to your holistic health journey." />
            </Helmet>
            <Navbar cart={1}/>
            <div className="Section-main">
                <div className="ourteam-header my-5">
                    <h1 className="h1C text-white py-5">Meet the Faces Behind the Magic</h1>
                </div>
                <div className="row ourteam ms-xl-3 me-xl-1">
                    <div className="col-12 col-xl-4 ps-0">
                        <div className="team-member">
                            <img src={User} alt="User" className="w-100 object-fit-cover team-image"/>
                            <div className="overlay ">
                                {/* <h4 className="text-white text-center">Visionary Founder & Energy Mastery Expert</h4> */}
                                <h6 className="text-white text-center" style={{width:"90%"}}>I blend ancient wisdom with modern science to unlock your healing potential. As co-author of the bestselling "Sleep Code," I'm passionate about empowering you to achieve vibrant health and peak performance through energy mastery.</h6>
                            </div>
                            <div className="p-2">
                                <h4 className="text-white py-1 mb-1 fw-semibold">Pradeep Kumar</h4>
                                <h5 className="text-white">Visionary Founder & Energy Mastery Expert</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-4 mt-3 mt-md-0 ps-0">
                        <div className="team-member">
                            <img src={User2} alt="User" className="w-100 object-fit-cover team-image"/>
                            <div className="overlay">
                                {/* <h4 className="text-white text-center">Intuitive Healer & Wellness Architect</h4> */}
                                <h6 className="text-white text-center" style={{width:"90%"}}>My journey from corporate executive to energy healer taught me the power of mind-body balance. I specialize in clearing energetic blockages and creating personalized wellness blueprints to help you live authentically and joyfully.</h6>
                            </div>
                            <div className="p-2">
                                <h4 className="text-white py-1 mb-1 fw-semibold">Trupti Shah</h4>
                                <h5 className="text-white">Intuitive Healer & Wellness Architect</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-4 mt-3 mt-md-0 ps-0">
                        <div className="team-member">
                            <img src={User3} alt="User" className=" w-100 object-fit-cover team-image"/>
                            <div className="overlay">
                                {/* <h4 className="text-white text-center">Digital Wellness Innovator & Next-Gen Healer</h4> */}
                                <h6 className="text-white text-center" style={{width:"90%"}}>I'm bringing holistic healing into the digital age. Through online courses and virtual sessions, I make energy work accessible and relevant to a new generation of wellness seekers, bridging traditional practices with cutting-edge technology.</h6>
                            </div>
                            <div className="p-2">
                                <h4 className="text-white py-1 mb-1 fw-semibold">Prapti Shahkumar</h4>
                                <h5 className="text-white">Digital Wellness Innovator & Next-Gen Healer</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default OurTeam;