import { useState , useEffect } from 'react';
import Navbar from '../Components/Common/Navbar/Navbar'
import Footer from '../Components/Common/Footer/footer';
import { useNavigate } from 'react-router-dom';
import config from './config';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import Swal from 'sweetalert2';
import Loader from './Loader';

const BlogPage = () => {

    const [blogCards,setblogcards] = useState([])

    const BlogContent = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [visibleCount, setVisibleCount] = useState(3);
    const [loading,setloading] = useState(false);

    const handleReadMore = (name) => {
        BlogContent(`${config.appUrl}blog/${name}`); // Navigate to /blog/:name route (only name in URL)
    };

    const setcategory = (name) => {
        setSelectedCategory(name);
        setVisibleCount(3);

    }

    const loadMoreBlogs = () => {
        setVisibleCount(visibleCount + 3); // Load 3 more blogs
    };

    const filteredBlogs = blogCards?.filter(blog => {
        const matchesSearch = blog.title.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesCategory = selectedCategory === 'all' || blog.type === selectedCategory;
        return matchesSearch && matchesCategory;
    });

    useEffect(() => {
        const fetchData = async () => {
            setloading(true);
            try {
                const response = await axios.get(`${config.backendlink}blogs/get`);
                const { data } = response;
                setblogcards(data?.data);
                setloading(false);
            } catch (error) {
                Swal.fire({
                    title: 'Error',
                    text: error,
                    icon: 'error',
                })
                setloading(false);
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []); 

    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return `${String(day).padStart(2, '0')}-${String(month).padStart(2, '0')}-${year}`;
    };

    return (
        <div className='BlogPage'>
            {loading && <Loader/>}
            <Helmet>
                <title>Holistic Healing Blog - artofselfhealing.ca</title>
                <meta name="description" content="Explore our blog for insights on holistic wellness, healing techniques, and mind-body harmony." />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Holistic Healing Blog - artofselfhealing.ca" />
                <meta property="og:description" content="Explore our blog for insights on holistic wellness, healing techniques, and mind-body harmony." />
                <meta property="og:url" content="https://artofselfhealing.ca/blog/" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Holistic Healing Blog - artofselfhealing.ca" />
                <meta name="twitter:description" content="Explore our blog for insights on holistic wellness, healing techniques, and mind-body harmony." />
            </Helmet>
            <Navbar cart={1}/>
            <div className='Blogpage-main'>
                <div className='blog-banner d-flex flex-column justify-content-center align-items-center my-5 px-2 px-md-0'>
                    <h1 className='h1C text-white p-1'>Welcome to Self Healing Blogs</h1>
                    <h4 className='h4C text-white p-2'>Always Filled With Latest News and Self Healing Techniques</h4>
                </div>

                <div className='filters my-4 ms-5 me-1 me-md-5'>
                    <input
                        type="text"
                        className='search-input p-2'
                        placeholder="Search Blogs"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <div className='category-buttons ms-0 ms-md-2 '>
                        <button
                            className={` category-button ${selectedCategory === 'all' ? 'active' : ''}`}
                            onClick={() => setcategory('all')}
                        >
                            All
                        </button>
                        <button
                            className={`ms-2 ms-xl-0 category-button ${selectedCategory === 'wellness' ? 'active' : ''}`}
                            onClick={() => setcategory('wellness')}
                        >
                            Wellness
                        </button>
                        <button
                            className={`ms-2 ms-xl-0 category-button ${selectedCategory === 'health' ? 'active' : ''}`}
                            onClick={() => setcategory('health')}
                        >
                            Health
                        </button>
                        <button
                            className={`mt-2 mt-xl-0 category-button ${selectedCategory === 'creativity' ? 'active' : ''}`}
                            onClick={() => setcategory('creativity')}
                        >
                            Creativity
                        </button>
                        <button
                            className={`ms-2 ms-md-0 category-button ${selectedCategory === 'spirituality' ? 'active' : ''}`}
                            onClick={() => setcategory('spirituality')}
                        >
                            Spirituality
                        </button>
                    </div>
                </div>

                

                <div className='blog-grid ms-xl-5'>
                    {filteredBlogs.slice(0, visibleCount).map((blog, index) => (
                            <div className='blog-card-test text-white position-relative'>
                                <img src={blog.background[0]} alt='Blog1' className='w-75 object-fit-cover position-absolute blog-test-image'/>
                                <div className='blog-test-content'>
                                    <h4 className='h4C lh-sm px-4 blog-title'>{blog.title}</h4>
                                    <h6 className='px-4 h6C'><i class="bi bi-calendar-fill pe-2"/>{formatDate(blog.date)}</h6>
                                    <div className='blog-divider'></div>
                                    <h6 className='px-4 h6C lh-base pt-3 blog-content' dangerouslySetInnerHTML={{__html : blog.content.split(".")[0]}}/>
                                    <button className='card-work-button mx-4 mt-1' onClick={() => handleReadMore(blog.slug)}>
                                       Explore More&nbsp;<span><i className="bi bi-chevron-right"></i></span>
                                    </button>
                                </div>
                            </div>
                    ))}
                </div>

                {filteredBlogs.length > visibleCount && (
                    <div className='learn-more-container my-4 d-flex justify-content-center'>
                        <button className='card-work-button' onClick={loadMoreBlogs}>
                            Discover More
                        </button>
                    </div>
                )}

                {/* <div className='blog-end mt-4'>
                    <div className='blog-end-div-text'>
                        <h3 className='blog-end-text2'>Get Free Self-Healing Techniques!</h3>
                    </div>
                    <div className='blog-end-div'>
                        <input className='blog-input' type="text" name="email" id="email" placeholder="Enter Your Email Address"></input>
                        <button className='card-work-button blog'>
                            <i className="bi bi-send"></i>
                        </button>
                    </div>
                </div> */}
            </div>
            <Footer />
        </div>
    )
}

export default BlogPage;