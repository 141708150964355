import React, { useEffect, useState } from "react";
import Footer from "../Components/Common/Footer/footer";
import Navbar from "../Components/Common/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import config from "./config";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import secureLocalStorage from "react-secure-storage";

const Cart = () => {
    const [cartItems, setCartItems] = useState([]);
    const [subtotal, setSubtotal] = useState(0);
    const [total, setTotal] = useState(0);
    const Navigate = useNavigate();

    useEffect(() => {
        // Retrieve cart from local storage
        const storedCart = JSON.parse(secureLocalStorage.getItem('cart')) || [];
        setCartItems(storedCart);
        calculateTotals(storedCart);
    }, []);

    const calculateTotals = (items) => {
        const subtotal = items.reduce((acc, item) => acc + (Number(item.price) * item.quantity), 0);
        setSubtotal(subtotal);
        setTotal(subtotal); // Assuming no shipping or taxes for simplicity
    };

    const handleRemoveItem = (itemIndex) => {
        // Remove item from cart
        const updatedCart = cartItems.filter((_, index) => index !== itemIndex);
        setCartItems(updatedCart);

        // Update local storage
        secureLocalStorage.setItem('cart', JSON.stringify(updatedCart));

        // Recalculate totals
        calculateTotals(updatedCart);
    };

    const handleQuantityChange = (index, newQuantity) => {
        if (newQuantity < 1) return; // Ensure quantity is at least 1

        const updatedCart = [...cartItems];
        updatedCart[index] = { ...updatedCart[index], quantity: newQuantity };
        setCartItems(updatedCart);

        // Update local storage
        secureLocalStorage.setItem('cart', JSON.stringify(updatedCart));

        // Recalculate totals
        calculateTotals(updatedCart);
    };

    if (cartItems.length === 0) {
        return (
            <div>
                <Helmet>
                    <title>Your Art of Self-Healing Cart - artofselfhealing.ca</title>
                    <meta name="description" content="Review your cart and continue shopping for healing tools to support your wellness journey." />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Your Art of Self-Healing Cart - artofselfhealing.ca" />
                    <meta property="og:description" content="Review your cart and continue shopping for healing tools to support your wellness journey." />
                    <meta property="og:url" content="https://artofselfhealing.ca/our-team/" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="Your Art of Self-Healing Cart - artofselfhealing.ca" />
                    <meta name="twitter:description" content="Review your cart and continue shopping for healing tools to support your wellness journey." />
                </Helmet>
                <Navbar />
                <div className="Section-main">
                    <h1 className="text-white pt-5 h1C">Your Cart is Empty</h1>
                </div>
                <Footer />
            </div>
        );
    }

    return (
        <div className="Cart">
            <Helmet>
                <title>Your Art of Self-Healing Cart - artofselfhealing.ca</title>
                <meta name="description" content="Review your cart and continue shopping for healing tools to support your wellness journey." />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Your Art of Self-Healing Cart - artofselfhealing.ca" />
                <meta property="og:description" content="Review your cart and continue shopping for healing tools to support your wellness journey." />
                <meta property="og:url" content="https://artofselfhealing.ca/our-team/" />
                <meta name="twitter:card" content="summary_large_image" />
		        <meta name="twitter:title" content="Your Art of Self-Healing Cart - artofselfhealing.ca" />
                <meta name="twitter:description" content="Review your cart and continue shopping for healing tools to support your wellness journey." />
            </Helmet>
            <Navbar />
            <div className="Section-main">
                <h1 className="h1C text-white text-center">Your Cart</h1>
                <div className="row mt-5 cart-row ms-xl-3 me-xl-1">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex">
                            <i className="bi bi-chevron-left text-white d-flex align-items-center"></i>
                            <h4 className="cart-icon h4C ps-1 mt-3" onClick={() => Navigate(-1)}>Continue Shopping</h4>
                        </div>
                        
                        <button className="card-work-button me-2 mb-2" onClick={() => Navigate(`${config.appUrl}contactus`)}>Consultation Call</button>
                    </div>
                    <hr className="Custom-hr" />
                    <div className="col-xl-9 pe-md-4 mt-3">
                        <div className="cart-container">
                        <div className="row display-flex align-items-center Cart-row">
                            <div className="col-2"></div>
                            <div className="col-3">
                                <h4 className="h4C fs-5 ps-0">Product</h4>
                            </div>
                            <div className="col-2">
                                <h4 className="h4C fs-5 ps-0">Quantity</h4>
                            </div>
                            <div className="col-2">
                                <h4 className="h4C fs-5 ps-0">Unit Price</h4>
                            </div>
                            <div className="col-2">
                                <h4 className="h4C fs-5 ps-0">Total Price</h4>
                            </div>
                            <div className="col-1"></div>
                        </div>
                        {cartItems.map((item, index) => (
                            <div key={index} className="row mt-3 display-flex justify-content-center align-items-center Cart-row">
                                <div className="col-2 d-flex align-items-center">
                                    <h4 className="h4C fs-5 ps-0 ps-md-2 pe-4">{index + 1}.</h4>
                                    <img src={item.image} alt="Product_Image" className="Cart_Image" />
                                </div>
                                <div className="col-3 h-100 d-flex align-items-center">
                                    <h4 className="h4C fs-5 ps-0">{item.name}</h4>
                                </div>
                                <div className="col-2 d-flex">
                                    <input
                                        type="text"
                                        min="1"
                                        value={item.quantity || 1}
                                        onChange={(e) => handleQuantityChange(index, parseInt(e.target.value, 10))}
                                        className="form-control cart"
                                        style={{width:"40%"}}
                                    />
                                    {item.id !== 7 &&(
                                        <div className="d-flex align-items-center">
                                            <button 
                                                className="card-work-button cart p-2" 
                                                onClick={() => handleQuantityChange(index, Math.max(item.quantity - 1, 1))}
                                            >
                                                <i className="bi bi-dash text-white"></i>
                                            </button>
                                            <button 
                                                className="card-work-button cart ms-2 p-2"
                                                onClick={() => handleQuantityChange(index, item.quantity + 1)}
                                            >
                                                <i className="bi bi-plus"></i>
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <div className="col-2">
                                    <h4 className="h4C fs-5 ps-0"><i className="bi bi-currency-dollar"></i>{parseFloat(item.price).toFixed(2)}</h4>
                                </div>
                                <div className="col-2">
                                    <h4 className="h4C fs-5 ps-0"><i className="bi bi-currency-dollar"></i>{parseFloat(item.price * item.quantity).toFixed(2)}</h4>
                                </div>
                                <div className="col-1">
                                    <i className="bi bi-trash3-fill text-white fs-md-5" onClick={() => handleRemoveItem(index)}></i>
                                </div>
                            </div>
                        ))}
                        </div>
                    </div>
                    <div className="col-xl-3 Checkout p-4 mt-3 mt-md-0">
                        <div className="row Checkout-row">
                            <div className="col-6 text-end">
                                <h5 className="h5C text-white ps-0">Subtotal: </h5>
                            </div>
                            <div className="col-6 ps-0 text-end">
                                <h5 className="h5C text-white ps-0"><i className="bi bi-currency-dollar"></i>{parseFloat(subtotal).toFixed(2)}</h5>
                            </div>
                        </div>
                        <div className="row Checkout-row">
                            <div className="col-6 text-end">
                                <h5 className="h5C fs-5 text-white ps-0">Total: </h5>
                            </div>
                            <div className="col-6 text-end">
                                <h5 className="h5C text-white ps-0"><i className="bi bi-currency-dollar"></i>{parseFloat(total).toFixed(2)}</h5>
                            </div>
                        </div>
                        <div className="row Checkout-button mt-4">
                            <button className="card-work-button" onClick={() => Navigate(`${config.appUrl}checkout`)}>Proceed To Checkout<i className="bi bi-chevron-right ps-1"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Cart;
