import { useEffect } from "react";
import Footer from "../../Components/Common/Footer/footer";
import Navbar from "../../Components/Common/Navbar/Navbar";
import Live1 from "../../Assets/Images/Services/Automated-details-1.jpg"
import { useNavigate } from "react-router-dom";
import config from "../config";
import { Helmet } from "react-helmet";

const SelfPaced  = () => {
    const CourseContent = useNavigate()

    const handleReadMore = () => {
        CourseContent(`${config.appUrl}mastering-effortless-manifestation`); // Navigate to /blog/:name route (only name in URL)
    };


    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return(
        <div className="Live Courses">
            <Helmet>
                <title>Effortless Manifestation Self-Paced Program - artofselfhealing.ca</title>
                <meta name="description" content="Master effortless manifestation with self-paced guidance to achieve your deepest goals." />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Effortless Manifestation Self-Paced Program - artofselfhealing.ca" />
                <meta property="og:description" content="Master effortless manifestation with self-paced guidance to achieve your deepest goals." />
                <meta property="og:url" content="https://artofselfhealing.ca/self-paced/" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Effortless Manifestation Self-Paced Program - artofselfhealing.ca" />
                <meta name="twitter:description" content="Master effortless manifestation with self-paced guidance to achieve your deepest goals." />
            </Helmet>
            <Navbar cart={1}/>
                <div className="Section-main">
                    <div className="live-course-header my-5">
                        <h1 className="text-white py-5 ps-2 ps-md-4 text-center">Self-Paced Learning</h1>
                    </div>

                    <div className="Service-grid ms-xl-3 d-flex justify-content-center">
                            <div className=" hover-container">
                                <img src={Live1} alt="Live1-image" className="w-100 object-fit-cover" />
                                    <h4 className="text-white mt-3 Main-Title">Mastering Effortless Manifestation</h4>
                                    {/* <h6 className="h6C text-white Main-Title Subtitle">{course.subtitle}</h6> */}
                                    <button className="card-work-button mt-4">See the Magic</button>
                                <div className="content text-start py-xl-4">
                                    <h3 className="text-white">Mastering Effortless Manifestation</h3>
                                    <div className="d-flex">
                                            <h6 className="pt-1 ps-2 pb-1 h6C"> Unlock your full potential and effortlessly bring your desires to fruition with our transformative Effortless Manifestation Program. From the comfort of your own space, embark on a journey of self-discovery and growth as you master powerful techniques to align your mind and energy. Our expert-guided program will empower you to manifest your dreams with clarity and ease, helping you create the life you’ve always envisioned. Start your path to success today and experience the profound impact of effortless manifestation.</h6>
                                        </div>
                                    <button className="card-work-button" onClick={() => handleReadMore()}>Explore More<span><i className="bi bi-chevron-right"></i></span></button>
                                </div>
                            </div>
                    </div>
                </div>
            <Footer/>
        </div>
    )

}

export default SelfPaced;