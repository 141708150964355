import logo from '../../../Assets/Images/Logo_new.png';
import './footer.css';
import { useNavigate } from "react-router-dom";
import config from "../../../Pages/config";

const Footer = () => {
    const navigate = useNavigate(); // Create navigate function

    const handleButtonClick = (path) => {
        navigate(`${config.appUrl}${path}`); // Concatenate base URL with the path
    };

    return (
        <div className="footer-main">
            <div className='d-flex flex-column flex-xl-row'>
                <div className="footer-start">
                    <img src={logo} alt="Logo" className="footer-logo" />
                    <h4 className="footer-h4 ps-xl-4">Centre For Hollistic Healing</h4>
                    <div className="footer-divider ms-xl-4"></div>
                    <p className="footer-p ps-xl-4">
                        With our extensive mentoring and hands-on experience in holistic wellness, we've guided numerous clients to achieve significant health transformations.
                    </p>
                    {/* <div className="footer-divider ms-xl-4"></div> */}
                </div>
                <div className="Footer-end ms-xl-5">
                    <div className="footer-links">
                        {/* <button className="footer-button" onClick={() => handleButtonClick('blogs')}>Blog</button>
                        <button className="footer-button" onClick={() => handleButtonClick('press-media')}>Media Gallery</button><br /> */}
                        <button className="footer-button" onClick={() => handleButtonClick('about-us')}>Our Journey</button><br />
                        <button className="footer-button" onClick={() => handleButtonClick('our-team')}>Our Team</button><br />
                        <button className="footer-button" onClick={() => handleButtonClick('privacy-policy')}>Privacy Policy</button><br />
                        <button className="footer-button" onClick={() => handleButtonClick('terms-condition')}>Terms & Conditions</button><br />
                        {/* <button className="footer-button" onClick={() => handleButtonClick('contactus')}>Contact Us</button><br /> */}
                    </div>
                    <div style={{ marginTop: "20px" }} className='footer-contact'>
                        <h5 className="footer-h5">Contact Us</h5>
                        <div className="footer-divider"></div>
                        <div style={{ display: 'flex', marginTop: '20px' }}>
                            <span><i className="bi bi-telephone-fill text-white"></i></span>
                            <a href="tel:+1(416)879-1535"><h6 className="footer-h6 S">+1 (416) 879‑1535</h6></a>
                        </div>

                        <div style={{ display: 'flex', marginTop: '10px' }}>
                            <span><i className="bi bi-envelope text-white"></i></span>
                            <a href="mailto:info@artofselfhealing.ca"><h6 className="footer-h6 S">info@artofselfhealing.ca</h6></a>
                        </div>

                    </div>
                    
                    <div className="Icons">
                        <div className="me-4">
                            <a href={`${config.instagram}`} target='_blank'><i className="bi bi-instagram text-white fs-3"></i></a>
                        </div>
                        <div className="me-4">
                            <a href={`${config.facebook}`} target='_blank'><i className="bi bi-facebook text-white fs-3"></i></a>
                        </div>
                        <div className="me-4">
                            <a href={`${config.youtube}`} target='_blank'><i className="bi bi-youtube text-white fs-3"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ps-copyright d-flex text-center pt-4">
                <p className='text-white mb-0 w-100'>&copy; {new Date().getFullYear()} Art of Self Healing. Designed & Developed By
                    <a href='https://www.zithas.com/ca' target='_blank' className='text-white fs-5'> Zithas Technologies</a>
                </p>
            </div>
        </div>
    );
}

export default Footer;
