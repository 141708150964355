import React, { useState, useEffect } from 'react';
import Footer from "../Components/Common/Footer/footer";
import Navbar from "../Components/Common/Navbar/Navbar";
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet';
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import config from './config';
import { loadStripe } from '@stripe/stripe-js';
import Loader from './Loader';
import { useNavigate } from 'react-router-dom';

const stripePromise = loadStripe(`${config.stripe_key}`);

const Checkout = () => {
    const [cartItems, setCartItems] = useState([]);
    const [subtotal, setSubtotal] = useState(0);
    const [taxes, setTaxes] = useState(0);
    const [total, setTotal] = useState(0);
    const [loading,setloading] = useState(false);
    const Navigate = useNavigate()
    const [billingDetails, setBillingDetails] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        streetaddress: '',
        city: '',
        postalcode: '',
        province: 'Alberta'
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const storedCart = JSON.parse(secureLocalStorage.getItem('cart')) || [];
        setCartItems(storedCart);

        const subtotal = storedCart.reduce((acc, item) => acc + (Number(item.price) * item.quantity), 0);
        setSubtotal(subtotal);
        
        setTaxes(0);

        setTotal(subtotal);
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
    
        // Format the phone number input
        if (name === 'phone') {
            // Remove non-digit characters
            const digits = value.replace(/\D/g, '');
    
            // Format the number
            let formattedPhone = '';
            if (digits.length > 0) {
                formattedPhone += digits.substring(0, 3);
            }
            if (digits.length > 3) {
                formattedPhone += '-' + digits.substring(3, 6);
            }
            if (digits.length > 6) {
                formattedPhone += '-' + digits.substring(6, 10);
            }
    
            // Update the formData state
            setBillingDetails({ ...billingDetails, [name]: formattedPhone });
        } else {
            setBillingDetails({ ...billingDetails, [name]: value });
        }
        setErrors({ ...errors, [name]: '' });
    };

    const validateFields = () => {
        const newErrors = {};
        const requiredFields = ['firstname', 'lastname', 'email', 'phone', 'streetaddress', 'city', 'postalcode', 'province'];

        requiredFields.forEach(field => {
            if (!billingDetails[field]) {
                if (field === 'email') {
                    newErrors[field] = "Email address is required";
                } else if (field === 'firstname') {
                    newErrors[field] = "First name is required";
                } else if (field === 'lastname') {
                    newErrors[field] = "Last name is required";
                } else if (field === 'streetaddress') {
                    newErrors[field] = "Street address is required";
                } else if (field === 'postalcode') {
                    newErrors[field] = "Postal code is required";
                } else if (field === 'city') {
                    newErrors[field] = "City name is required";
                } else {
                    newErrors[field] = `${field.replace(/([A-Z])/g, ' $1')} is required`;
                }
            }
        });

        if (billingDetails.email && !/\S+@\S+\.\S+/.test(billingDetails.email)) {
            newErrors.email = "Email address is invalid";
        }

        if (billingDetails.phone && !/^\d{3}-\d{3}-\d{4}$/.test(billingDetails.phone)) {
            newErrors.phone = "Phone number must be 10 digits";
        }

        return newErrors;
    };

    function getDate() {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const date = today.getDate();
        return `${date}/${month}/${year}`;
      }

    const handlePurchase = async () => {
        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setloading(true);

        // Calculate total quantity and courses
        const totalQuantity = cartItems.map(item => item.quantity).join(', ');
        const courseList = cartItems.map(item => item.name).join(', ');
        const id = cartItems.map(item => item.id).join(',');

        const Order_id = secureLocalStorage.getItem("order_id");
        const purchaseData = {
            id : Order_id ? Order_id : null,
            course_id : id,
            first_name: billingDetails.firstname,
            last_name: billingDetails.lastname,
            email: billingDetails.email,
            phone: billingDetails.phone,
            address: billingDetails.streetaddress,
            city: billingDetails.city,
            province: billingDetails.province,
            tax: (parseFloat(taxes).toFixed(2)),
            price: (parseFloat(subtotal).toFixed(2)),
            total: (parseFloat(total).toFixed(2)),
            quantity: totalQuantity,
            status: 'pending',
            postal_code: billingDetails.postalcode,
            course: courseList,
            date : getDate()
        };

        const stripe = await stripePromise;
        
        try {
            if (parseFloat(total) === 0) {
                // Call another API for free purchase
                const freePurchaseResponse = await axios.post(`${config.backendlink}order/store`, purchaseData);
                if (freePurchaseResponse.status === 200) {
                    setloading(false);
                    // Handle successful free purchase
                    Navigate(`${config.appUrl}success`)
                    // You may want to redirect or show a success message here
                } else {
                    throw new Error('Free purchase failed');
                }
            } else {
                // Proceed with the paid purchase
                
                const response = await axios.post(`${config.backendlink}checkout/session`, purchaseData);
                if (response.status === 200) {
                    setloading(false);
                    // Get Stripe Session Id
                    const session = await response.data;
                    secureLocalStorage.setItem('order_id', session.order_id);
                    // Redirect to Stripe Checkout
                    const result = await stripe.redirectToCheckout({
                        sessionId: session.id,
                    });
    
                    if (result.error) {
                        // Handle error here
                        Swal.fire({
                            icon: "error",
                            title: "Payment Failed",
                            text: result.error.message,
                        });
                        console.error(result.error.message);
                    }
                } else {
                    throw new Error('Purchase failed');
                }
            }
        } catch (error) {
            setloading(false);
            Swal.fire('Error!', error, 'error');
        }
    };

    return (
        <div className="CheckOut">
            {loading && <Loader/>}
            <Helmet>
                <title>Checkout - artofselfhealing.ca</title>
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Checkout - artofselfhealing.ca" />
                <meta property="og:url" content="https://artofselfhealing.ca/checkout/" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Checkout - artofselfhealing.ca" />
            </Helmet>
            <Navbar cart={1}/>
            <div className="Section-main">
                <h1 className="h1C text-white text-center">Check Out</h1>
                <div className="row mx-xl-1">
                    <div className="col-xl-6 text-white">
                        <div className="User_Details">
                            <h4 className="h3C text-white">Billing Address :</h4>
                            <div className="row">
                                <div className="col-xl-6">
                                    <input
                                        className="Contact-form-input"
                                        name="firstname"
                                        placeholder="First Name"
                                        value={billingDetails.firstname}
                                        onChange={handleInputChange}
                                    />
                                    {errors.firstname && <span className="text-danger">{errors.firstname}</span>}
                                </div>
                                <div className="col-xl-6">
                                    <input
                                        className="Contact-form-input"
                                        name="lastname"
                                        placeholder="Last Name"
                                        value={billingDetails.lastname}
                                        onChange={handleInputChange}
                                    />
                                    {errors.lastname && <span className="text-danger">{errors.lastname}</span>}
                                </div>
                            </div>
                            <input
                                className="Contact-form-input"
                                name="email"
                                placeholder="Email Address"
                                value={billingDetails.email}
                                onChange={handleInputChange}
                            />
                            {errors.email && <span className="text-danger">{errors.email}</span>}
                            <input
                                className="Contact-form-input"
                                name="phone"
                                placeholder="Phone Number"
                                value={billingDetails.phone}
                                onChange={handleInputChange}
                            />
                            {errors.phone && <span className="text-danger">{errors.phone}</span>}
                            <input
                                className="Contact-form-input"
                                name="streetaddress"
                                placeholder="Street Address"
                                value={billingDetails.streetaddress}
                                onChange={handleInputChange}
                            />
                            {errors.streetaddress && <span className="text-danger">{errors.streetaddress}</span>}
                            <select
                                className="Contact-form-input"
                                name="province"
                                placeholder="Province"
                                value={billingDetails.province}
                                onChange={handleInputChange}>
                                <option>Alberta</option>
                                <option>British Columbia</option>
                                <option>Manitoba</option>
                                <option>New Brunswick</option>
                                <option>New Foundland and Labrador</option>
                                <option>Northwest Territories</option>
                                <option>Nova Scotia</option>
                                <option>Nunavut</option>
                                <option>Ontario</option>
                                <option>Prince Edward Island</option>
                                <option>Quebec</option>
                                <option>Saskatchewan</option>
                                <option>Yukon</option>
                            </select>   
                            {errors.province && <span className="text-danger">{errors.province}</span>}
                            <div className="row">
                                <div className="col-xl-6">
                                    <input
                                        className="Contact-form-input"
                                        name="city"
                                        placeholder="City"
                                        value={billingDetails.city}
                                        onChange={handleInputChange}
                                    />
                                    {errors.city && <span className="text-danger">{errors.city}</span>}
                                </div>
                                <div className="col-xl-6">
                                    <input
                                        className="Contact-form-input"
                                        name="postalcode"
                                        placeholder="Postal Code"
                                        maxLength={6}
                                        value={billingDetails.postalcode}
                                        onChange={handleInputChange}
                                    />
                                    {errors.postalcode && <span className="text-danger">{errors.postalcode}</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 mt-4 text-white">
                        <h4 className="h4C ps-0">Purchases</h4>
                        <div className='cart-container'>
                            {cartItems.map((item, index) => (
                                <div key={index} className="row mt-3 display-flex justify-content-center align-items-center Cart-row">
                                    <div className="col-1">
                                        <h5 className="h5C ps-0 ps-md-2">{index + 1}.</h5>
                                    </div>
                                    <div className="col-2">
                                        <img src={item.image} alt="Product_Image" className="w-100 object-fit-cover" />
                                    </div>
                                    <div className="col-5">
                                        <h5 className="h5C ps-0">{item.name}</h5>
                                    </div>
                                    <div className="col-1">
                                        <h5 className="h5C ps-0">{item.quantity}</h5>
                                    </div>
                                    <div className="col-3">
                                        <h5 className="h5C ps-0"><i class="bi bi-currency-dollar"></i>{parseFloat(item.price).toFixed(2)}</h5>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="row Checkout-row mt-3 text-end">
                            <div className="col-8">
                                <h5 className="h5C text-white ps-0">Subtotal: </h5>
                            </div>
                            <div className="col-4">
                                <h5 className="h5C text-white ps-0"><i class="bi bi-currency-dollar"></i>{parseFloat(subtotal).toFixed(2)}</h5>
                            </div>
                            {/* <div className="col-8">
                                <h5 className="h5C text-white ps-0">Taxes: (HST) </h5>
                            </div>
                            <div className="col-3">
                                <h5 className="h5C text-white ps-0"><i class="bi bi-currency-dollar"></i>{parseFloat(taxes).toFixed(2)}</h5>
                            </div> */}
                            {/* <div className="Coupen d-flex justify-content-end pe-5">
                                <input className="Contact-form-input w-50" placeholder="Enter Coupon Code" />
                                <button className="card-work-button h-75 mt-3 ms-3">Apply</button>
                            </div> */}
                        </div>
                        <div className="row Checkout-row mt-3 text-end">
                            <div className="col-8">
                                <h5 className="h5C text-white ps-0">Total (Incl. Taxes): </h5>
                            </div>
                            <div className="col-4">
                                <h5 className="h5C text-white ps-0"><i class="bi bi-currency-dollar pe-2"></i>{parseFloat(total).toFixed(2)}</h5>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mt-4">
                            <button className="card-work-button" onClick={handlePurchase}>
                                Pay Now
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Checkout;
