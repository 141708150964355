import React, { useState } from "react";
import Navbar from "../Components/Common/Navbar/Navbar";
import Footer from "../Components/Common/Footer/footer";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";
import { InlineWidget } from "react-calendly";
import axios from "axios";
import config from "./config";
import Loader from "./Loader";

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        message: ''
    });
    const [loading,setloading] = useState(false);

    const [errors, setErrors] = useState({});

    const customToastStyle = {
        backgroundColor: '#333',
        color: '#fff',
        fontSize: '16px',
        borderRadius: '8px',
        margintop: '20px',
    };

    const handleGetCourseClick = async () => {
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return; // Stop the submission if there are errors
        }

        try {
            setloading(true);
            const response = await axios.post(`${config.backendlink}getintouch/store`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.status === 200) {
                setloading(false);
                toast.success("Message Sent Successfully", {
                    style: customToastStyle,
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // Clear the form and errors after successful submission
                setFormData({ name: '', contact: '', email: '', message: '' });
                setErrors({});
                
            } else {
                setloading(false);
                throw new Error("Failed to send message");
            }
        } catch (error) {
            toast.error("Error sending message. Please try again.", {
                style: customToastStyle,
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setloading(false);
        }
    };

    const validateForm = () => {
        const errors = {};
        if (!formData.name) errors.name = "Name is required";
        if (!formData.phone) {
            errors.phone = "Contact number is required";
        } else if (!/^\d{3}-\d{3}-\d{4}$/.test(formData.phone)) {
            errors.phone = "Contact number must be in the format xxx-xxx-xxxx";
        }
        if (!formData.email) {
            errors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = "Email is invalid";
        }
        if (!formData.message) errors.message = "Message is required";
        return errors;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
    
        // Format the phone number input
        if (name === 'phone') {
            // Remove non-digit characters
            const digits = value.replace(/\D/g, '');
    
            // Format the number
            let formattedPhone = '';
            if (digits.length > 0) {
                formattedPhone += digits.substring(0, 3);
            }
            if (digits.length > 3) {
                formattedPhone += '-' + digits.substring(3, 6);
            }
            if (digits.length > 6) {
                formattedPhone += '-' + digits.substring(6, 10);
            }
    
            // Update the formData state
            setFormData({
                ...formData,
                [name]: formattedPhone
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
        setErrors({ ...errors, [name]: '' }); // Clear error on change
    };

    // Social media link handlers
    const handleSocialMediaClick = (url) => {
        window.open(url, '_blank'); // Opens URL in a new tab
    };

    return (
        <div className="Contact-Section">
            {loading && <Loader/>}
            <Helmet>
                <title>Contact Art of Self-Healing - artofselfhealing.ca</title>
                <meta name="description" content="Reach out to us for inquiries about our healing programs, products, and wellness services." />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Contact Art of Self-Healing  - artofselfhealing.ca" />
                <meta property="og:description" content="Reach out to us for inquiries about our healing programs, products, and wellness services." />
                <meta property="og:url" content="https://artofselfhealing.ca/our-team/" />
                <meta name="twitter:card" content="summary_large_image" />
		        <meta name="twitter:title" content="Contact Art of Self-Healing  - artofselfhealing.ca" />
                <meta name="twitter:description" content="Reach out to us for inquiries about our healing programs, products, and wellness services." />
            </Helmet>
            <Navbar cart={1} />
            <div className="Section-main">
                <h1 className="h1C w-100 mt-5 text-white">Book Your Consultation Call / Appointment</h1>
                <div className="row">
                    <InlineWidget url="https://calendly.com/info-39901/ash-consultation-call" data-cookie-policy="none" styles={{ height: '700px', marginTop: '0' }} />
                </div>
                <div className="Contact-middle mt-5 mt-xl-0">
                    <div className="row contact">
                        <div className="col-12 col-xl-7 Contact">
                            <div className="Contact-form">
                                <h4 className="h4C ps-0 fs-3">Get In Touch</h4>
                                
                                <label htmlFor="name" className="mt-3">Name</label>
                                <input
                                    type="text"
                                    id="name" // Add id for accessibility
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    className="Contact-form-input mt-2"
                                    placeholder="Enter your name"
                                />
                                {errors.name && <span className="text-danger">{errors.name}</span>}
                                
                                <label htmlFor="phone" className="mt-3">Contact No.</label>
                                <input
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                    className="Contact-form-input mt-2"
                                    placeholder="Enter your contact number"
                                />
                                {errors.phone && <span className="text-danger">{errors.phone}</span>}
                                
                                <label htmlFor="email" className="mt-3">Email Address</label>
                                <input
                                    type="text"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    className="Contact-form-input mt-2"
                                    placeholder="Enter your email address"
                                />
                                {errors.email && <span className="text-danger">{errors.email}</span>}
                                
                                <label htmlFor="message" className="mt-3">Your Message</label>
                                <textarea
                                    id="message"
                                    name="message"
                                    rows="5"
                                    value={formData.message}
                                    onChange={handleInputChange}
                                    className="Contact-form-input mt-2"
                                    placeholder="Type your message here"
                                />
                                {errors.message && <span className="text-danger">{errors.message}</span>}
                                
                                <div>
                                    <button className="card-work-button mt-3" onClick={handleGetCourseClick}>
                                        Send Message <i className="bi bi-send ps-1"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-5 Contact mt-3 mt-xl-0">
                            <div className="Contact-details">
                                <h3>Contact Information</h3>
                                <br />
                                <div style={{ display: 'flex', marginTop: '10px' }}>
                                    <span className="pt-1"><i className="bi bi-geo-alt-fill"></i></span>
                                    <h6 className="Contact-data lh-base">Brampton, Ontario, Canada</h6>
                                </div>
                                <br />
                                <div style={{ display: 'flex', marginTop: '10px' }}>
                                    <span><i className="bi bi-telephone-fill"></i></span>
                                    <a href='tel:+1(416)879-1535'><h6 className="footer-h6 S">+1 (416) 879‑1535</h6></a>
                                </div>
                                <br />
                                <div className="mt-4">
                                    <h6 className="Contact-email">You Can Also Write Your Questions on <br /><a href="mailto:info@artofselfhealing.ca"><b className="text-white"><i className="bi bi-envelope-fill pe-1"></i> info@artofselfhealing.ca </b></a><br /> to Get Fast Response.</h6>
                                </div>
                                <div className="d-flex mt-4">
                                    <div className="me-3">
                                        <a href={`${config.instagram}`} target='_blank'><i className="bi bi-instagram text-white fs-3"></i></a>
                                    </div>
                                    <div className="me-3">
                                        <a href={`${config.facebook}`} target='_blank'><i className="bi bi-facebook text-white fs-4"></i></a>
                                    </div>
                                    <div className="me-3">
                                    <a href={`${config.youtube}`} target='_blank'><i className="bi bi-youtube text-white fs-4"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer className="mt-5" position="top-right" autoClose={3000} hideProgressBar={false} />
            <Footer />
        </div>
    );
}

export default Contact;
