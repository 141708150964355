import Navbar from "../Components/Common/Navbar/Navbar"
import Footer from "../Components/Common/Footer/footer"
import image from "../Assets/Images/Payment_Failed.png"
import { Helmet } from "react-helmet"
import config from "./config"
import { useNavigate } from "react-router-dom"

const PaymentCancel = () => {

    const Navigate = useNavigate();
    
    return(
        <div>
            <Helmet>
                <title>Payment Error - artofselfhealing.ca</title>
                <meta name="description" content="Payment" />
            </Helmet>
            <Navbar cart={1}/>
            <div className="Section-main  text-white">
                <div className="Payment">
                    <img src={image} alt="Success" className="Success-image"/>
                    <h1 className="h1C ">Something Went Wrong!</h1>
                    <h4 className="h4C pb-3">Payment Failed</h4>
                    <button className="card-work-button" onClick={() => Navigate(`${config.appUrl}`)}>Go to Homepage</button>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default PaymentCancel