import Footer from "../Components/Common/Footer/footer"
import Navbar from "../Components/Common/Navbar/Navbar"
import Aboutus1 from "../Assets/Images/Aboutus1.jpeg"
import Lifestyle from "../Assets/Images/lifestyle.png"
import Mind from "../Assets/Images/mind.png"
import Sleep from "../Assets/Images/sleep.png"
import { useEffect } from "react"
import { Helmet } from "react-helmet"
import { Description } from "@mui/icons-material"

const AboutUs = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return(
        <div className="AboutUs">
            <Helmet>
                <title>About Art of Self-Healing - artofselfhealing.ca</title>
                <meta name="description" content="Learn about Art of Self-Healing's mission to bring holistic wellness to everyone, everywhere." />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="About Art of Self-Healing - artofselfhealing.ca" />
                <meta property="og:description" content="Learn about Art of Self-Healing's mission to bring holistic wellness to everyone, everywhere." />
                <meta property="og:url" content="https://artofselfhealing.ca/about-us/" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="About Art of Self-Healing - artofselfhealing.ca" />
                <meta name="twitter:description" content="Learn about Art of Self-Healing's mission to bring holistic wellness to everyone, everywhere." /> 
            </Helmet>
            <Navbar cart={1}/>
            <div className="AboutUs-main">
                <div className="AboutUs-Head row ms-xl-4">
                    <div className="AboutUs-head-details col-md-6 ">
                        <h1 className="fw-semibold">No Matter Where You are on Your Self Healing Journey,</h1>
                        <h4 className="h4C fw-semibold p-0 py-3">You'll Always Find Our Door Open.</h4>
                        <p className='aboutus-p fw-semibold'>We are the Visionary Founders of Art of Self Healing Inc. and the Authors of Internationally Best-Selling Book &#8220;Sleep Code.&#8221; 
                            As dedicated holistic wellness mentors and energy healers, we harness the transformative power of energy to empower individuals on their journey to healing and vibrant living in all areas of life.</p>
                    </div>
                    <div className="col-md-5 p-0 d-flex align-items-center">
                        <img src={Aboutus1} alt="Aboutus" className="Aboutus-head-img"/>
                    </div>
                </div>
                <div className="row ms-xl-2">
                    <div className="col-md-2"></div>
                    <div className="col-md-8 mt-5 px-4 AboutUs-mid-details">
                        <h1 className="h1C text-left fw-semibold ">What Is Self Healing?</h1>
                        <p className='aboutus-p fw-semibold'>As per the Indian Vedic philosophy which is one of the world’s most ancient spiritual philosophies, all healing is ultimately an expression of our own divine source, the invisible spiritual Self and “self” is defined as an ordinary, ever-present awareness.</p>
                        <p className='aboutus-p fw-semibold '>The Sanskrit term for abiding health is “swastha,” which means “to be established in your spiritual Self” by working with your energies to bring mind, body and soul alignment. In Indian culture, self-healing is integrated into every Indian way of life. The Art of Self Healing is to attend the state of utter peace in all aspects of your life by understanding, learning, and practicing the Vedic philosophy.</p>
                    </div>
                </div>
                <div className="row pb-5 ms-xl-2">
                    <div className="col-md-2"></div>
                    <div className="col-md-8 mt-5 px-4 aboutus-middle4-content">
                        <h1 className="h1C fw-semibold">What We Have For You?</h1>
                        <h3 className="overflow-hidden ">Dear friends,</h3>
                        <p className="aboutus-p fw-semibold">If you are looking for a permanent solution to your well-being, and want to live a life full of energy, happiness, and serenity just in the comfort of your home – then this is the most important message you’ll read today.</p>
                        <p className="aboutus-p fw-semibold">Here’s what that looks like:</p>
                        <div className="row">
                            <div className="col-md-4 p-3">
                                <img src={Lifestyle} alt="Tick" className="w-25 object-fit-cover bg-white rounded-pill p-1"></img>
                                <p className="aboutus-p fw-semibold"><b>Renew Lifestyle:</b> <br/>  A transformation from unconscious living to conscious living</p>
                            </div>
                            <div className="col-md-4 bg-success rounded p-3">
                                <img src={Mind} alt="Tick" className="w-25 object-fit-cover bg-black rounded-pill p-1"></img>
                                <p className="aboutus-p fw-semibold"><b>Recode Mind:</b> <br/>A smooth transition from exhausted mind to energetic mind</p>
                            </div>
                            <div className="col-md-4 p-3">
                                <img src={Sleep} alt="Tick" className="w-25 object-fit-cover bg-white rounded-pill p-1"></img>
                                <p className="aboutus-p fw-semibold"><b>Restore Sleep:</b> <br/> A shift from restless sleep to restful sleep</p>
                            </div>
                        </div>
                        <br/>
                        <p className="aboutus-p fw-semibold">We are offering you the foundational steps, tips, and strategies to live where and how you want.</p>
                    </div>
                    
                </div>  
            </div>
            <Footer/>
        </div>
    )

}

export default AboutUs