import Section1 from "../Components/Homepage/Header";
import Navbar from "../Components/Common/Navbar/Navbar";
import Footer from "../Components/Common/Footer/footer";
import Section3 from "../Components/Homepage/How-Work";
import Section6 from "../Components/Homepage/Programs";
import Section7 from "../Components/Homepage/Books";
import Online_wellness from "../Components/Homepage/online_wellness";
import Online_wellness_Program from "../Components/Homepage/online_wellness_program";
import Self_paced from "../Components/Homepage/self_paced";

const Homepage = () =>{
    return(
        <div className="Homepage">
            <Navbar cart={1}/>
            <div className="Homepage-main">
                <div className="Homepage-content">
                <Section1/>
                <Section3/>
                <Section6/>
                <Online_wellness/>
                <Online_wellness_Program/>
                <Self_paced/>
                <Section7/>
                </div>
                <Footer/>
            </div> 
        </div>
    )
}

export default Homepage;