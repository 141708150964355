import Footer from "../../Components/Common/Footer/footer";
import Navbar from "../../Components/Common/Navbar/Navbar";
import Live1 from "../../Assets/Images/Services/local1.png"
import Live2 from "../../Assets/Images/Services/Massage.png"
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import config from "../config";
import { Helmet } from "react-helmet";

const LocalCourses = () => {

    const CourseContent = useNavigate()

    const courseDetails = [
        {
            key: "reiki-services",
            title: "Reiki Healing Service",
            subtitle: "",
            description: "Experience profound relaxation and inner peace with our local Reiki services, where certified practitioners use gentle touch to channel healing energy throughout your body. Our sessions are designed to promote physical, emotional, and spiritual balance, helping you reduce stress, manage pain, and enhance overall well-being. Book your personalized Reiki appointment today and embark on a journey towards holistic healing.",
            image: Live1,
        },
        {
            key: "massage-therapy-non-rmt-massages",
            title: "Healing Massage",
            subtitle: "",
            description: "Immerse yourself in the soothing experience of our Healing Massage, a comprehensive full-body treatment designed to rejuvenate and restore. Using warm or lukewarm herbal oils, our skilled therapists perform a series of techniques tailored to your individual needs, promoting deep relaxation and relieving tension. The application of these specially selected oils not only enhances the massage's effectiveness but also provides surprising health benefits, such as improved circulation, reduced muscle stiffness, and detoxification of the body. Experience a profound sense of well-being and balance as each session works to harmonize your body, mind, and spirit. Book your Healing Massage today and discover the transformative power of this therapeutic treatment.",
            image: Live2,
        },
    ];

    const handleReadMore = (name) => {
        CourseContent(`${config.appUrl}local/${name}`); // Navigate to /blog/:name route (only name in URL)
    };


    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return(
        <div className="local Courses">
            <Helmet>
                <title>Hands-On Wellness for Holistic Healing</title>
                <meta name="description" content="Experience hands-on wellness techniques to unlock true holistic healing and personal transformation." />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Hands-On Wellness for Holistic Healing" />
                <meta property="og:description" content="Experience hands-on wellness techniques to unlock true holistic healing and personal transformation." />
                <meta property="og:url" content="https://artofselfhealing.ca/hands-on-wellness/" />
                <meta name="twitter:title" content="Hands-On Wellness for Holistic Healing" />
                <meta name="twitter:description" content="Experience hands-on wellness techniques to unlock true holistic healing and personal transformation." />
            </Helmet>
            <Navbar cart={1}/>
                <div className="Section-main text-white">
                    <div className="local-course-header my-5">
                        <h1 className="text-white py-5 ps-2 ps-md-4 text-center">Hands-On Wellness Services</h1>
                    </div>
                    {/* First Course */}
                    <div className="Service-grid ms-xl-3 justify-content-center">
                        {courseDetails.slice(0,1).map((course, index) => (
                            <div className=" hover-container hands1">
                                <img src={course.image} alt="Live1-image" className="w-100 object-fit-cover" />
                                    <h4 className="text-white mt-3 Main-Title">{course.title}</h4>
                                    {/* <h6 className="h6C text-white Main-Title Subtitle">{course.subtitle}</h6> */}
                                    <button className="card-work-button">See the Magic</button>
                                <div className="content text-start">
                                    <h3 className="text-white">{course.title}</h3>
                                    <h5 className="">{course.subtitle}</h5>
                                    <div className="d-flex">
                                            <h6 className="pt-1 pb-1 h6C"> {course.description}</h6>
                                        </div>
                                    <button className="card-work-button" onClick={() => handleReadMore(course.key)}>Explore More<span><i className="bi bi-chevron-right"></i></span></button>
                                </div>
                            </div>
                        ))}
                        {courseDetails.slice(1,2).map((course, index) => (
                            <div className=" hover-container  hands2 ms-xl-4">
                                <img src={course.image} alt="Live1-image" className="w-100 object-fit-cover" />
                                    <h4 className="text-white mt-3 Main-Title">{course.title}</h4>
                                    {/* <h6 className="h6C text-white Main-Title Subtitle">{course.subtitle}</h6> */}
                                    <button className="card-work-button">See the Magic</button>
                                <div className="content text-start">
                                    <h3 className="text-white">{course.title}</h3>
                                    <h5 className="">{course.subtitle}</h5>
                                    <div className="d-flex">
                                            <h6 className="pt-1 pb-1 h6C"> {course.description}</h6>
                                        </div>
                                    <button className="card-work-button" onClick={() => handleReadMore(course.key)}>Explore More<span><i className="bi bi-chevron-right"></i></span></button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            <Footer/>
        </div>
    )

}

export default LocalCourses;