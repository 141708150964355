import { useNavigate } from "react-router-dom";
import config from "../../Pages/config";
import Live4 from "../../Assets/Images/Services/Yoga.jfif"
import Live5 from "../../Assets/Images/Services/Pranayana.png"
import Live6 from "../../Assets/Images/Services/Meditation.png"
import Reiki5 from "../../Assets/Images/Services/Long.jfif"
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay:true,
    arrows : false,
    autoplaySpeed: 3000,
    responsive: [
        {
            breakpoint: 500, // when the viewport width is 500px or less
            settings: {
                slidesToShow: 1, // show only one slide at a time
                slidesToScroll: 1, // scroll one slide at a time
            }
        }
    ] 
  };


const Online_wellness = () => {

    const Navigate = useNavigate();

    const handleReadMore = (name) => {
        Navigate(`${config.appUrl}live/${name}`)
    }

    return(
        <div>
            <div className="Homepage-Section">
                <div className="Main">
                    <h1 className="ps-2 text-white mb-2">Online Wellness Services</h1>
                    <h4 className="h4C lh-base mt-4">Embrace holistic healing from the comfort of your home with our tailored online wellness services, offering personalized sessions that cater to your unique needs and provide transformative wellness in your own peaceful environment.</h4>
                </div>
                <div className="Slider-Main ms-xl-3 me-xl-3 mt-5">
                    <Slider {...settings}>
                    <div className="slide-item">
                        <div className="card1 course test">
                            <img src={Live4} alt="Course1" className="Course-Image" />
                            <h4 className="Section9-h4">
                                Yoga Therapy
                            </h4>
                            <p className="Section9-p">Our personalized Yoga Therapy sessions address your unique physical, mental, and emotional needs, offering a holistic approach to achieving balance and overall well-being.<br/></p>
                        </div>
                    </div>
                    <div className="slide-item">
                        <div className="card1 course test">
                            <img src={Reiki5} alt="Course1" className="Course-Image" />
                            <h4 className="Section9-h4">
                                Long-Distance Reiki Healing
                            </h4>
                            <p className="Section9-p">Experience the transformative power of Reiki from anywhere, as our long-distance sessions help balance your energy, promote healing, and enhance your physical, emotional, and spiritual well-being.</p>
                            {/* <button className="Price-button Two">
                                <span><i className="bi bi-arrow-up-right"></i></span>
                            </button> */}

                        </div>
                    </div>
                    <div className="slide-item">
                        <div className="card1 course test">
                            <img src={Live5} alt="Course1" className="Course-Image" />
                            <h4 className="Section9-h4">
                                Pranayama (Breath Work)
                            </h4>
                            <p className="Section9-p">Our online Pranayama sessions use ancient breathwork techniques to reduce stress, enhance mental clarity, and bring balance to your body, mind, and spirit for overall well-being.</p>
                        </div>
                    </div>
                    <div className="slide-item">
                        <div className="card1 course test">
                            <img src={Live6} alt="Course1" className="Course-Image test" />
                            <h4 className="Section9-h4">
                                Meditation
                            </h4>
                            <p className="Section9-p">Our online meditation sessions, rooted in ancient yogic practices, promote relaxation, reduce stress, and enhance mental clarity, all from the comfort of your home.</p>
                        </div>
                    </div>
                    </Slider>
                </div>
                <div className="Col-12 ps-xl-1">
                    <div className="Cources">
                        
                        
                        
                    </div>
                    <div className='d-flex justify-content-center mt-3'>
                        <button className='card-work-button' onClick={() => Navigate(`${config.appUrl}online-wellness-services`)}>Unlock On-line Wellness Services&nbsp;<span><i className="bi bi-chevron-right"></i></span></button>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Online_wellness;