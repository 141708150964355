import { useNavigate } from "react-router-dom";
import config from "../../Pages/config";
import img1 from "../../Assets/Images/Services/Reiki_Certification.png"
import img2 from "../../Assets/Images/Services/Crystal.png"
import img3 from "../../Assets/Images/Services/Long.jfif"
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import img4 from "../../Assets/Images/Services/Automated-details-1.jpg"


const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay:true,
    autoplaySpeed: 3000,
    arrows:false,
    responsive: [
        {
            breakpoint: 500, // when the viewport width is 500px or less
            settings: {
                slidesToShow: 1, // show only one slide at a time
                slidesToScroll: 1, // scroll one slide at a time
            }
        }
    ] 
  };

const Online_wellness_Program = () => {

    const Navigate = useNavigate();

    const handleReadMore = (name) => {
        Navigate(`${config.appUrl}live/${name}`)
    }

    return(
        <div>
            <div className="Homepage-Section">
                <div className="Main">
                    <h1 className="ps-2 text-white mb-2">Online Wellness Programs & Certification</h1>
                    <h4 className="h4C lh-base my-3 mt-4">Unlock the transformative power of holistic healing with our tailored online wellness programs and certifications, designed to cater to your unique needs and promote wellness from the comfort of your own serene environment.</h4>
                </div>

                <div className="Slider-Main ms-xl-3 me-xl-3 mt-5">
                    <Slider {...settings}>
                    <div className="slide-item">
                        <div className="card1 course test" >
                            <img src={img1} alt="Course1" className="Course-Image" />
                            <h4 className="Section9-h4">
                                Reiki Certification
                            </h4>
                            <p className="Section9-p">Embark on a journey to becoming a certified Reiki practitioner. Our online Reiki Certification course equips you with the skills and knowledge to channel healing energy effectively, empowering you to help yourself and others.</p>
                        </div>
                    </div>
                    <div className="slide-item">
                        <div className="card1 course test">
                            <img src={img2} alt="Course1" className="Course-Image" />
                            <h4 className="Section9-h4">
                                Crystal Reiki Master Certification
                            </h4>
                            <p className="Section9-p">Elevate your practice with our Crystal Reiki Master Certification. This advanced program blends Reiki healing with the powerful energies of crystals, offering you deeper insights and skills to enhance your healing abilities.</p>
                            {/* <button className="Price-button Two">
                                <span><i className="bi bi-arrow-up-right"></i></span>
                            </button> */}

                        </div>
                    </div>
                    <div className="slide-item">
                        <div className="card1 course test" >
                            <img src={img4} alt="Course1" className="Course-Image test" />
                            <h4 className="Section9-h4">
                                Mastering Effortless Manifestation Program
                            </h4>
                            <p className="Section9-p">Unlock your potential and manifest your desires effortlessly with our guided program. Learn techniques to align your mind and energy for optimal results and create the life you envision.</p>
                        </div>
                    </div>
                    <div className="slide-item">
                        <div className="card1 course test" >
                            <img src={img3} alt="Course1" className="Course-Image test" />
                            <h4 className="Section9-h4">
                                Mastering Deep Sleep Program
                            </h4>
                            <p className="Section9-p">Transform your sleep quality with our expert-designed program focused on achieving restorative, deep sleep. Discover techniques and practices that promote relaxation and rejuvenation, ensuring you wake up refreshed and energized.</p>
                        </div>
                    </div>
                    </Slider>
                </div>
                <div className='d-flex justify-content-center mt-3'>
                    <button className='card-work-button' onClick={() => Navigate(`${config.appUrl}online-wellness-progams`)}>Unlock On-line Wellness Programs & Certification&nbsp;<span><i className="bi bi-chevron-right"></i></span></button>
                </div>
            </div>
        </div>
    )

}

export default Online_wellness_Program;