import Navbar from "../Components/Common/Navbar/Navbar"
import image from "../Assets/Images/Successful-Payment.png"
import { Helmet } from "react-helmet"
import Footer from "../Components/Common/Footer/footer"
import { useLocation, useNavigate } from "react-router-dom"
import config from "./config"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import Swal from "sweetalert2"
import axios from "axios"
import Loader from './Loader';
import secureLocalStorage from "react-secure-storage"

const PaymentSuccess = () => {

    const Navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const session_id = query.get('session_id'); 
    const [loading ,setloading] = useState(true);

    // Fetch data on component mount
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${config.backendlink}sessionData/${session_id}`);
                if (response.status === 200) {
                    // Assuming you want to return true if the response is successful
                    secureLocalStorage.removeItem('cart');
                    secureLocalStorage.removeItem("order_id");
                    setloading(false); 
                } else {
                    // Handle non-200 status codes
                    setloading(false);
                    secureLocalStorage.removeItem('cart');
                    secureLocalStorage.removeItem("order_id");
                    Navigate(`${config.appUrl}cancel`);
                }
            } catch (error) {
                setloading(false);
                secureLocalStorage.removeItem('cart');
                Navigate(`${config.appUrl}cancel`);
            }
        };

        if(session_id !== null){
            fetchData();
        }else{
            setloading(false);
            secureLocalStorage.removeItem('cart');
            Navigate(`${config.appUrl}cancel`);
        }
    }, [session_id]); 

    return(
        <div>
            <Helmet>
                <title>Success - artofselfhealing.ca</title>
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Success - artofselfhealing.ca" />
                <meta property="og:url" content="https://artofselfhealing.ca/Success/" />
                <meta name="twitter:card" content="summary_large_image" />
		        <meta name="twitter:title" content="Success - artofselfhealing.ca" />
                
            </Helmet>
            {loading ? (
                <>
                    <Navbar cart={1}/>
                        <div className="Section-main">
                        </div>
                        <Loader/>
                    <Footer/>
                </>
            ):(
                <>
                    <Navbar cart={1}/>
                        <div className="Section-main new  text-white">
                            <div className="Payment">
                                <img src={image} alt="Success" className="Success-image"/>
                                <h1 className="h1C">Payment Successfull</h1>
                                <button className="card-work-button" onClick={() => Navigate(`${config.appUrl}`)}>Go to Homepage</button>
                            </div>
                        </div>
                    <Footer/>
                </>
            )}
            
        </div>
    )
}

export default PaymentSuccess