import { Helmet } from "react-helmet";
import Footer from "../Components/Common/Footer/footer";
import Navbar from "../Components/Common/Navbar/Navbar";
import config from "./config";


const Privacy = () => {
    return(
        <div className="privacy">
            <Helmet>
                <title>Privacy Policy - artofselfhealing.ca</title>
                <meta name="description" content="Payment" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Privacy Policy - artofselfhealing.ca" />
                <meta property="og:url" content="https://artofselfhealing.ca/privacy-policy/" />
                <meta name="twitter:card" content="summary_large_image" />
		        <meta name="twitter:title" content="Privacy Policy - artofselfhealing.ca" />
            </Helmet>
            <Navbar/>
            <div className="Section-main">
                <h1 className="mt-5 text-center text-white">Privacy Policy for Art of Self Healing</h1>
                <div className="Reiki_data1 new my-4">
                    <h5 className="text-white">Last Updated : 08/10/2024</h5>
                    <div className="mt-4">
                        <h3 className="text-white">1. Introduction</h3>
                        <div className="d-flex">
                            <i className="pe-4 me-xl-1"></i>
                            <h5 className="text-white lh-base">Art of Self Healing is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website <a href={`${config.appUrl}`}>{config.fullurl}</a> and use our services. Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.</h5>
                        </div>
                    </div>
                    <div className="mt-4">
                        <h3 className="text-white">2. Information We Collect</h3>
                        <h5 className="text-white lh-base ps-4 ms-xl-1">2.1 Personal Information <br/>
                            <div className="ps-4 ms-xl-1 mt-2">We may collect personal information that you provide to us, such as:
                            <ul className="my-2 ms-2">
                                <li>Name</li><li>Email address</li><li>Phone number</li><li>Billing and payment information</li><li>Any other information you choose to provide</li>
                            </ul>
                            </div>
                        </h5>
                        <div className="d-flex">
                            <h5 className="text-white lh-base ps-4 pe-2 ms-xl-1">2.2</h5>
                            <h5 className="text-white lh-base  ms-xl-1">Automatically Collected Information <br/></h5>
                        </div>
                        <div className="ps-4 ms-xl-1 mt-2">
                            <h5 className="text-white ps-4 ms-xl-1">When you visit our website, we may automatically collect certain information about your device, including:
                                <ul className="my-2 ms-2">
                                    <li>IP address</li><li>Browser type</li><li>Operating system</li><li>Access times</li><li>Pages viewed</li>
                                </ul>
                            </h5>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div className="d-flex">
                            <h3 className="text-white pe-2">3.</h3>
                            <h3 className="text-white"> How We Use Your Information</h3>
                        </div>
                        <h5 className="text-white lh-base ps-4 ms-xl-1">We use the information we collect to:
                            <ul className="my-3">
                                <li>Provide, operate, and maintain our website</li>
                                <li>Improve, personalize, and expand our website</li>
                                <li>Understand and analyze how you use our website</li>
                                <li>Develop new products, services, features, and functionality</li>
                                <li>Communicate with you, either directly or through one of our partners, for customer service, updates, and other website-related purposes</li>
                                <li>Send you emails</li>
                                <li>Find and prevent fraud</li>
                            </ul>
                        </h5>
                    </div>
                    <div className="mt-4">
                        <div className="d-flex">
                            <h3 className="text-white pe-2">4.</h3>
                            <h3 className="text-white">How We Share Your Information</h3>
                        </div>
                        <h5 className="text-white lh-base ps-4 ms-xl-1">We may share your information with:
                            <ul className="my-3">
                                <li>Service providers who assist us in operating our website and conducting our business</li>
                                <li>Law enforcement or government agencies in response to a legal request</li>
                                <li>Other parties with your consent or at your direction</li>
                            </ul>
                            We do not sell, trade, or otherwise transfer your personally identifiable information to outside parties.
                        </h5>
                    </div>
                    <div className="mt-4">
                        <h3 className="text-white"><span>5.</span> Data Security</h3>
                        <h5 className="text-white lh-base ms-4 ps-xl-1">We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, please note that no method of transmission over the Internet or method of electronic storage is 100% secure.</h5>
                    </div>
                    <div className="mt-4">
                        <h3 className="text-white"><span>6.</span> Your Data Protection Rights</h3>
                        <h5 className="text-white lh-base ms-4 ps-xl-1">Depending on your location, you may have certain rights regarding your personal information, such as:
                            <ul className="my-3">
                                <li>The right to access</li>
                                <li>The right to rectification</li>
                                <li>The right to erasure</li>
                                <li>The right to restrict processing</li>
                                <li>The right to data portability</li>
                                <li>The right to object</li>
                                <li>Rights in relation to automated decision making and profiling</li>
                            </ul>
                            To exercise any of these rights, please contact us using the information provided in the "Contact Us" section.
                        </h5>
                    </div>
                    <div className="mt-4">
                        <h3 className="text-white"><span>7.</span> Children's Privacy</h3>
                        <h5 className="text-white lh-base ms-4 ps-xl-1">Our website is not intended for children under 13 years of age. We do not knowingly collect personal information from children under 13.</h5>
                    </div>
                    <div className="mt-4">
                        <div className="d-flex">
                            <h3 className="text-white pe-2">8.</h3>
                            <h3 className="text-white"> Changes to This Privacy Policy</h3>
                        </div>
                        
                        <h5 className="text-white lh-base ms-4 ps-xl-1">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date.</h5>
                    </div>
                    <div className="mt-4">
                        <h3 className="text-white"><span>9.</span> Contact Us</h3>
                        <h5 className="text-white lh-base ms-4 ps-xl-1">If you have any questions about this Privacy Policy, please contact us at:
                            <ul>
                                <li>Email: <a href="mailto:info@artofselfhealing.ca ">info@artofselfhealing.ca </a></li>
                                <li>Phone: <a href="call:+1 (416) 879‑1535">+1 (416) 879‑1535</a></li>
                            </ul>
                        </h5>
                        
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Privacy;