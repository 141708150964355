import Footer from "../../Components/Common/Footer/footer"
import Navbar from "../../Components/Common/Navbar/Navbar"
import Product2 from "../../Assets/Images/Book_image.jpg"
import Product4 from "../../Assets/Images/Services/Product4.png"
import Product5 from "../../Assets/Images/Services/Product5.png"
import { useNavigate } from "react-router-dom"
import config from "../config"
import Product_Video from "../../Assets/Images/Product_Page.mp4"
import { Helmet } from "react-helmet"

const Products = () => {


    const BookClick = useNavigate();

    const handleBookClick = () => {
        BookClick(`${config.appUrl}sleep-code-e-book/`)
    }

    const handleYoga = () => {
        window.open('https://shareasale.com/r.cfm?b=1357169&u=3649571&m=30960&urllink=&afftrack=','_blank')
    }

    const handleSkin = () => {
        window.open('https://shareasale.com/r.cfm?b=2383135&u=3649571&m=63953&urllink=&afftrack=','_blank')
    }

    return(
        <div className="Products">
            <Helmet>
                <title>Healing Products for Mind & Body - artofselfhealing.ca</title>
                <meta name="description" content="Shop holistic products designed to support healing and enhance wellness in every aspect of life." />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Healing Products for Mind & Body - artofselfhealing.ca" />
                <meta property="og:description" content="Shop holistic products designed to support healing and enhance wellness in every aspect of life." />
                <meta property="og:url" content="https://artofselfhealing.ca/products/" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Healing Products for Mind & Body - artofselfhealing.ca" />
                <meta name="twitter:description" content="Shop holistic products designed to support healing and enhance wellness in every aspect of life." />
                
            </Helmet>
            <Navbar cart={1}/>
                <div className="Section-main">
                    <div className="products-header">
                        <h1 className="text-white py-5 ps-3 text-center">Our Products</h1>
                    </div>
                    <div className="d-flex justify-content-center ms-xl-3">
                        <video
                            src={Product_Video}
                            className="w-75 object-fit-cover"
                            controls
                        />
                    </div>
                    <div className="products-main d-flex flex-column flex-xl-row p-2 ps-xl-3 mt-4">
                        <div className="card1 P">
                            <img src={Product2} alt="Course1" className="Course-Image P" />
                            <h4 className="Section9-h4">
                                Sleep Code
                            </h4>
                            <button className="card-work-button products ms-3 mb-3" onClick={handleBookClick}>Buy Now&nbsp;<span><i className="bi bi-chevron-right"></i></span></button>
                        </div>
                        <div className="card1 P">
                            <img src={Product4} alt="Course1" className="Course-Image P" />
                            <h4 className="Section9-h4">
                                Yoga Download
                            </h4>
                            <button className="card-work-button products ms-3 mb-3" onClick={() => handleYoga()}>Join Now&nbsp;<span><i className="bi bi-chevron-right"></i></span></button>
                        </div>
                        <div className="card1 P me-0">
                            <img src={Product5} alt="Course1" className="Course-Image P" />
                            <h4 className="Section9-h4">
                                Mosturirizing & Age-defying Kit
                            </h4>
                            <button className="card-work-button products ms-3 mb-3" onClick={() => handleSkin()}>Get the Complete Kit&nbsp;<span><i className="bi bi-chevron-right"></i></span></button>
                        </div>
                    </div>
                </div>
            <Footer/>
        </div>
    )

}

export default Products;