import { useNavigate } from 'react-router-dom';
import Image from '../../Assets/Images/SC-3.png'
import config from '../../Pages/config';

const Book = () => {

    const Navigate = useNavigate();

    return(

        <div className="Section7">
            <div className="Homepage-Section text-white">
                <div className="row overflow-visible">
                    <div className="col-12 col-xl-12">
                        
                        <div className="Secton7-content">
                            <h1 className='Section7-h1'>
                                Read Our Book To Renew Your Lifestyle
                            </h1>
                            <div className='row Section7'>
                                <div className='col-12 col-xl-4'>
                                    <img src={Image} alt="Book" className=""/>
                                </div>
                                <div className='col-12 col-xl-4'>
                                    <h4 className='Section7-h4'>
                                        If you’re struggling to..
                                    </h4>
                                    <div className="d-flex">
                                        <span style={{fontSize:"30px"}}>&#x2022;</span>
                                        <h6 className="pt-1 ps-2 pb-1 h6C">All asleep at night</h6>
                                    </div>
                                    <div className="d-flex">
                                        <span style={{fontSize:"30px"}}>&#x2022;</span>
                                        <h6 className="pt-1 ps-2 pb-1 h6C">Get adequate sleep at night</h6>
                                    </div>
                                    <div className="d-flex">
                                        <span style={{fontSize:"30px"}}>&#x2022;</span>
                                        <h6 className="pt-1 ps-2 pb-1 h6C">Return to sleep after having a nightmare</h6>
                                    </div>
                                    <div className="d-flex">
                                        <span style={{fontSize:"30px"}}>&#x2022;</span>
                                        <h6 className="pt-1 ps-2 pb-1 h6C">Wakeup recharged and refreshed</h6>
                                    </div>
                                    <div className="d-flex">
                                        <span style={{fontSize:"30px"}}>&#x2022;</span>
                                        <h6 className="pt-1 ps-2 pb-1 h6C">Focus & remember things</h6>
                                    </div>
                                </div>

                                <div className='col-12 col-xl-4'>
                                    <h4 className='Section7-h4'>
                                        You’ll get practical guideline to..
                                    </h4>
                                    <div className="d-flex">
                                        <span style={{fontSize:"30px"}}>&#x2022;</span>
                                        <h6 className="pt-1 ps-2 pb-1 h6C">Bring harmony in your life</h6>
                                    </div>
                                    <div className="d-flex">
                                        <span style={{fontSize:"30px"}}>&#x2022;</span>
                                        <h6 className="pt-1 ps-2 pb-1 h6C">Develop a peaceful & happy mind</h6>
                                    </div>
                                    <div className="d-flex">
                                        <span style={{fontSize:"30px"}}>&#x2022;</span>
                                        <h6 className="pt-1 ps-2 pb-1 h6C">Develop a positive attitude</h6>
                                    </div>
                                    <div className="d-flex">
                                        <span style={{fontSize:"30px"}}>&#x2022;</span>
                                        <h6 className="pt-1 ps-2 pb-1 h6C">Conscious breathing</h6>
                                    </div>
                                    <div className="d-flex">
                                        <span style={{fontSize:"30px"}}>&#x2022;</span>
                                        <h6 className="pt-1 ps-2 pb-1 h6C">Adopt a sleep routine</h6>
                                    </div>
                                    <div className="d-flex">
                                        <span style={{fontSize:"30px"}}>&#x2022;</span>
                                        <h6 className="pt-1 ps-2 pb-1 h6C">Attain a deep sleep at night</h6>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <button class="card-work-button ms-3 my-3" onClick={() => Navigate(`${config.appUrl}sleep-code-e-book/`)}>Get More Details&nbsp;<span><i class="bi bi-chevron-right"></i></span></button>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
    )

}

export default Book;