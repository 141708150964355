import Footer from "../Components/Common/Footer/footer";
import Navbar from "../Components/Common/Navbar/Navbar";
import config from "./config";
import { Helmet } from "react-helmet";

const Terms = () => {
    return(
        <div className="Terms">
            <Helmet>
                <title>Terms & Conditions - artofselfhealing.ca</title>
                <meta name="description" content="Terms & Conditions" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Terms & Conditions - artofselfhealing.ca" />
                <meta property="og:url" content="https://artofselfhealing.ca/terms-condition/" />
                <meta name="twitter:card" content="summary_large_image" />
		        <meta name="twitter:title" content="Terms & Conditions - artofselfhealing.ca" />
            </Helmet>
            <Navbar/>
            <div className="Section-main">
                <h1 className="mt-5 text-center text-white">Terms & Conditions for Art of Self Healing</h1>
                <div className="Reiki_data1 new my-4">
                    <h5 className="text-white">Last Updated : 08/10/2024</h5>
                    <h5 className="text-white lh-base mt-3">Welcome to Art of Self Healing. By accessing and using our website, you agree to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern our relationship with you in relation to this website. If you do not agree with any part of these terms, please do not use our website.</h5>
                    <div className="mt-4">
                        <h3 className="text-white">1. Introduction</h3>
                        <h5 className="text-white lh-base ps-4 ms-xl-1">The terms “we,” “us,” and “our” refer to Art of Self Healing, the owner of the website. The term “you” refers to the user or viewer of our website.</h5>
                    </div>
                    <div className="mt-4">
                        <h3 className="text-white">2. Use of the Website</h3>
                        <h5 className="text-white lh-base ps-4 ms-xl-1">The content of this website is for your general information and use only. It is subject to change without notice.
                            <ul className="my-2">
                                <li>You agree not to use this website for any unlawful purposes and to respect all applicable local, provincial, national, and international laws and regulations.</li><li>Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offense.</li>
                            </ul>
                        </h5>
                    </div>
                    <div className="mt-4">
                        <h3 className="text-white">3. Content</h3>
                        <h5 className="text-white lh-base ps-4 ms-xl-1">All content on this website, including but not limited to text, graphics, logos, images, videos, and software, is the intellectual property of Art of Self Healing unless otherwise stated.
                            <ul className="my-3">
                                <li>You may not reproduce, distribute, or transmit any content from this website without prior written consent from us.</li>
                                <li>We make no warranties or guarantees as to the accuracy, performance, or completeness of the information and materials found or offered on this website.</li>
                            </ul>
                        </h5>
                    </div>
                    <div className="mt-4">
                        <h3 className="text-white">4. User Submissions</h3>
                        <h5 className="text-white lh-base ps-4 ms-xl-1">If you submit any content (e.g., comments, feedback, or testimonials) to our website, you grant us a non-exclusive, royalty-free, perpetual, and worldwide license to use, reproduce, edit, and distribute such content in connection with promoting our services.
                            <ul className="my-3">
                                <li>You must not post any content that is defamatory, obscene, offensive, or in violation of any laws or regulations.</li>
                            </ul>
                        </h5>
                    </div>
                    <div className="mt-4">
                        <h3 className="text-white">5. Health and Wellness Information</h3>
                        <h5 className="text-white lh-base ps-4 ms-xl-1">Art of Self Healing provides content related to health and wellness practices, such as Reiki, Ayurveda, and other alternative healing modalities.
                            <ul className="my-3">
                                <li>The information provided on this site is for educational and informational purposes only and is not intended to be a substitute for professional medical advice, diagnosis, or treatment.</li>
                                <li>Always seek the advice of your physician or qualified health provider with any questions you may have regarding a medical condition. Never disregard professional medical advice or delay in seeking it because of something you have read on this website.</li>
                            </ul>
                        </h5>
                    </div>
                    <div className="mt-4">
                        <h3 className="text-white">6. Third-Party Links</h3>
                        <h5 className="text-white lh-base ps-4 ms-xl-1">This website may contain links to third-party websites. These links are provided for your convenience and do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).</h5>
                    </div>
                    <div className="mt-4">
                        <h3 className="text-white">7. Limitation of Liability</h3>
                        <h5 className="text-white lh-base ps-4 ms-xl-1">We will not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from your use of this website or the content herein.
                            <ul className="my-3">
                                <li>While we make every effort to ensure the site remains available and functional, we cannot guarantee uninterrupted access to the website.</li>
                            </ul>
                        </h5>
                    </div>
                    <div className="mt-4">
                        <h3 className="text-white">8. Changes to Terms</h3>
                        <h5 className="text-white lh-base ps-4 ms-xl-1">We reserve the right to modify these terms and conditions at any time. Any changes will be posted on this page, and your continued use of the website after such changes have been made will constitute your acceptance of the new terms.</h5>
                    </div>
                    <div className="mt-4">
                        <h3 className="text-white">9. Governing Law</h3>
                        <h5 className="text-white lh-base ps-4 ms-xl-1">These terms and conditions are governed by and construed in accordance with the laws of Canada and the province of Ontario, and you irrevocably submit to the exclusive jurisdiction of the courts in that location.</h5>
                    </div>
                    <div className="mt-4">
                        <h3 className="text-white">10. Contact Information</h3>
                        <h5 className="text-white lh-base ps-4 ms-3 ms-xl-4">If you have any questions about these Terms and Conditions, please contact us at:
                            <ul>
                                <li>Email: <a href="mailto:info@artofselfhealing.ca ">info@artofselfhealing.ca </a></li>
                                <li>Phone: <a href="call:+1 (416) 879‑1535">+1 (416) 879‑1535</a></li>
                            </ul>
                        </h5>
                        
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Terms;