import { useNavigate } from "react-router-dom";
import Footer from "../Components/Common/Footer/footer";
import Navbar from "../Components/Common/Navbar/Navbar";
import config from "./config";
import Image_Found from "../Assets/Images/404-test.png"
import { Helmet } from "react-helmet";

const NotFound = () => {

    const Navigate = useNavigate()


    return(
        <div className="Notfound">
            <Helmet>
                <title>404 - artofselfhealing.ca</title>
                <meta name="description" content="Page Not Found" />
            </Helmet>
            <Navbar cart={1}/>
            <div className="Section-main text-center">
                <img src={Image_Found} className="w-25 object-fit-cover pt-5" alt="404"/>
                <h1 className="text-white mt-4">Looks like you’ve hit a dead end</h1>
                <button className="card-work-button mt-4" onClick={() => Navigate(`${config.appUrl}`)}>Go to HomePage</button>
            </div>
            <Footer/>
        </div>
        
    )

}

export default NotFound;