import React, { useState } from 'react';
import Footer from "../Components/Common/Footer/footer";
import Navbar from "../Components/Common/Navbar/Navbar";
import YouTube from "react-youtube";
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { useEffect } from 'react';
import config from './config';
import Swal from 'sweetalert2';
import Loader from './Loader';

const Press = () => {


    const [videos,setvideos] = useState([])
    const [podcast , setpodcast] = useState([])
    const [loading , setLoading] = useState(false);

    // State to hold the currently selected video ID
    const [videoId, setVideoId] = useState(); // Default video
    const [podcastId,setPodcastId] = useState();

    // Full Screen Images
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    

    const [images,setimages] = useState([]);

    const handleImageClick = (index) => {
        setCurrentImageIndex(index);
        setLightboxOpen(true);
    };

    const closeLightbox = () => setLightboxOpen(false);

    const goToPrevious = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const goToNext = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };


    // Handler function to change video ID
    const handleVideoChange = (id) => {
        setVideoId(id);
    };

    const handlepodcastchange = (id) => {
        setPodcastId(id);
    }

    const currentVideo = videos.find(video => video.image.split('v=')[1] === videoId);
    const videoTitle = currentVideo ? currentVideo.title : "No Video Selected";
    const VideoSubtitle = currentVideo ? currentVideo.description : "No Description";


    const currentpodcast = podcast.find(video => video.image.split('v=')[1] === podcastId);
    const podcastTitle = currentpodcast ? currentpodcast.title : "No Podcast Selected";
    const podcastSubtitle = currentpodcast ? currentpodcast.description : "No Description";

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const response = await axios.get(`${config.backendlink}gallery/get`);
                const { data } = response;
                setimages(data?.gallery)
                const allVideos = data?.video || [];
                const reversedVideos = allVideos.reverse();
                // Set first three videos from the reversed array
                setvideos(reversedVideos.slice(0, 3));
                // Set fourth and fifth videos for podcast from the reversed array
                setpodcast(reversedVideos.slice(3));
                setVideoId(reversedVideos[0]?.image.split("v=")[1])
                setPodcastId(reversedVideos[3]?.image.split("v=")[1])
                setLoading(false);
            } catch (error) {
                Swal.fire({
                    title: 'Error',
                    text: error,
                    icon: 'error',
                })
                console.error('Error fetching banner data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []); 

    return (
        <div className="Press">
            {loading && <Loader/>}
            <Helmet>
                <title>Art of Self-Healing Press & Media - artofselfhealing.ca</title>
                <meta name="description" content="Explore our latest features in the press, media coverage, and holistic wellness success stories." />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Art of Self-Healing Press & Media - artofselfhealing.ca" />
                <meta property="og:description" content="Explore our latest features in the press, media coverage, and holistic wellness success stories." />
                <meta property="og:url" content="https://artofselfhealing.ca/press-media/" />
                <meta name="twitter:card" content="summary_large_image" />
    	    	<meta name="twitter:title" content="Art of Self-Healing Press & Media - artofselfhealing.ca" />
	    	    <meta name="twitter:description" content="Explore our latest features in the press, media coverage, and holistic wellness success stories." />
            </Helmet>
            {lightboxOpen && (
                <Lightbox
                    mainSrc={images[currentImageIndex].images}
                    nextSrc={images[(currentImageIndex + 1) % images.length]}
                    prevSrc={images[(currentImageIndex + images.length - 1) % images.length]}
                    onCloseRequest={closeLightbox}
                    onMovePrevRequest={goToPrevious}
                    onMoveNextRequest={goToNext}
                />
            )}
            <Navbar cart={1}/>
            <div className="Section-main">
                <h1 className="h1C text-white my-5">Media Gallery</h1>
                <div className='row mt-5 ms-xl-2 pt-0 pt-md-5 d-flex justify-content-center'>

                    {/* <h2 className='h2C text-white fw-bold text-center'>Photo Highlights</h2> */}
                    <div className='col-xl-6 pt-0 pt-md-5'>
                        <img src={images[0]?.images}   className='w-100 mt-2 mt-md-0 object-fit-cover Gallery-Image1' onClick={() => handleImageClick(0)}/>
                        <div className='row'>
                            <div className='col-xl-6 pe-0'>
                                <img src={images[1]?.images}  className='w-100 mt-2 Gallery-Image2' onClick={() => handleImageClick(1)}/>
                                <img src={images[2]?.images}  className='w-100 mt-2 Gallery-Image2' onClick={() => handleImageClick(2)}/>
                                <img src={images[3]?.images}  className='w-100 mt-2 Gallery-Image3' onClick={() => handleImageClick(3)}/>
                            </div>
                            <div className='col-xl-6'>
                                <img src={images[4]?.images}  className='w-100 mt-2 Gallery-Image2' onClick={() => handleImageClick(4)}/>
                                <img src={images[5]?.images}  className='w-100 mt-2 Gallery-Image2' onClick={() => handleImageClick(5)}/>
                                <img src={images[6]?.images}  className='w-100 mt-2 Gallery-Image3' onClick={() => handleImageClick(6)}/>
                            </div>
                        </div>
                        
                        
                    </div>
                    <div className='col-xl-3 ps-0 overflow-hidden pe-0'>
                        <img src={images[7]?.images}  className='w-100 mt-2 Gallery-Image3' onClick={() => handleImageClick(7)}/>
                        <img src={images[8]?.images}  className='w-100 mt-2 Gallery-Image3' onClick={() => handleImageClick(8)}/>
                        <img src={images[9]?.images}  className='w-100 mt-2 object-fit-cover Gallery-Image3' onClick={() => handleImageClick(9)}/>
                        <img src={images[10]?.images}  className='w-100 mt-2 object-fit-cover Gallery-Image3' onClick={() => handleImageClick(10)}/>
                    </div>
                    <div className='col-xl-3 overflow-hidden pt-md-3' >
                        <img src={images[11]?.images}  className='w-100 mt-2 object-fit-cover Gallery-Image3' onClick={() => handleImageClick(11)}/>
                        <img src={images[12]?.images}  className='w-100 mt-2 object-fit-cover Gallery-Image3' onClick={() => handleImageClick(12)}/>
                        <img src={images[13]?.images}  className='w-100 mt-2 object-fit-cover Gallery-Image3' onClick={() => handleImageClick(13)}/>
                        <img src={images[14]?.images}  className='w-100 mt-2 object-fit-cover Gallery-Image3' onClick={() => handleImageClick(14)}/>
                    </div>
                </div>
                <div className="row ms-xl-3 me-xl-1 my-5 p-0 p-md-5 video justify-content-center">
                    <h1 className='h1C text-start text-white'>Video Showcase</h1>
                    <div className="col-12 mt-3 pe-xl-5">
                        <div className='pt-2 d-xl-flex'>
                            {videos.map((video) => (
                                <div>
                                    <div
                                        key={video.id}
                                        className={`mt-3 mt-xl-0 me-xl-3 p-xl-2 option press ${video.image.split('v=')[1] === videoId ? 'active' : 'inactive'}`}
                                        onClick={() => handleVideoChange(video.image.split('v=')[1])}
                                    >
                                        <img
                                            src={`https://img.youtube.com/vi/${video.image.split('v=')[1]}/hqdefault.jpg`}
                                            alt={video.title}
                                            className="w-50 h-100 object-fit-cover"
                                        />
                                        <h6 className='h6C text-white w-100 ps-2'>
                                            {video.title}
                                        </h6>
                                    </div>
                                    {/* <div className='divider'></div> */}
                                </div>
                            ))}
                        </div>
                        <div className="video-responsive mt-4">
                            <YouTube
                                className="Youtube_video"
                                videoId={videoId}
                            />
                        </div>
                        <h2 className='h2C text-white ps-0 mt-4'>{videoTitle}</h2>
                        <h4 className='h4C text-white ps-0 lh-base mt-3'>{VideoSubtitle}</h4>
                    </div>
                </div>
                <div className="row ms-xl-3 me-xl-1 my-5 p-0 p-md-5 video left">
                    <h1 className='h1C text-start text-white'>Listen Now</h1>
                        <div className="col-12 mt-3 pe-xl-5">
                            <div className='pt-2 d-xl-flex'>
                                    {podcast.map((podcast) => (
                                        <div>
                                            <div
                                                key={podcast.id}
                                                className={`mt-3 mt-xl-0 me-xl-3 p-2 option press ${podcast.image.split('v=')[1] === podcastId ? 'active' : 'inactive'}`}
                                                onClick={() => handlepodcastchange(podcast.image.split('v=')[1])}
                                            >
                                                <img
                                                    src={`https://img.youtube.com/vi/${podcast.image.split('v=')[1]}/hqdefault.jpg`}
                                                    alt={podcast.title}
                                                    className="w-50 h-100 object-fit-cover"
                                                />
                                                <h6 className='h6C text-white w-100 ps-2'>
                                                    {podcast.title}
                                                </h6>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="video-responsive mt-4">
                                    <YouTube
                                        className="Youtube_video"
                                        videoId={podcastId}
                                    />
                                </div>
                                <h2 className='h2C text-white ps-0 mt-4'>{podcastTitle}</h2>
                                <h4 className='h4C text-white ps-0 mt-3 lh-base'>{podcastSubtitle}</h4>
                            </div>
                        </div>            
                </div>
            <Footer />
        </div>
    );
}

export default Press;
