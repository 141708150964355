import Footer from "../Components/Common/Footer/footer";
import Navbar from "../Components/Common/Navbar/Navbar";
import User from "../Assets/Images/Aboutus1.jpeg";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import config from "./config";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import Loader from "./Loader";

const BlogDetail = () => {
    const Navigate = useNavigate();
    const location = useLocation();
    const [blogdata, setblogdata] = useState([]);
    const [latest, setlatest] = useState([]);
    const [loading,setloading] = useState(false);
    const [blogimage,setimage] = useState();

    const handleBlogClick = (name) => {
        Navigate(`${config.appUrl}blog/${name}`);
    };

    const slug = location.pathname.split("/").pop(); // Extract slug from URL

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = String(date.getFullYear()).slice(-4); // Get last two digits of the year
    
        return `${day}-${month}-${year}`;
    };

    useEffect(() => {
        const fetchBlogData = async () => {
            setloading(true);
            try {
                const response = await axios.get(`${config.backendlink}blogs/details/${slug}`); // Replace with your API endpoint
                const blogData = response.data;
                setblogdata(blogData.data);
                setlatest(blogData.latestBlogs);
                setimage(blogData?.data?.background[1] ? blogData?.data?.background[1] : blogData?.data?.background[0]);
                console.log(blogimage);
                setloading(false);
            } catch (error) {
                Swal.fire("Error", "Failed to fetch blog data", "error");
                setloading(false);
            }
        };

        fetchBlogData();
    }, [slug]);
    

    return (
        <div className="BlogDetails">
            {loading && <Loader/>}
            <Helmet>
                <title>{`${blogdata?.title} - artofselfhealing.ca`}</title>
                <meta name="description" content={blogdata?.meta_description} />
                <meta property="og:type" content="article" />
                <meta property="og:title" content={`${blogdata?.title} - artofselfhealing.ca`}/>
                <meta property="og:description" content={blogdata?.meta_description} />
                <meta property="og:url" content={`https://artofselfhealing.ca/${slug}`} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={`${blogdata?.title} - artofselfhealing.ca`} />
                <meta name="twitter:description" content={blogdata?.meta_description} />
            </Helmet>
            <Navbar cart={1} />
            <div className="Section-main blog text-white">
                <div className="blog-main ps-md-4 pb-md-5">
                    <h1 className="text-white pt-5">{blogdata.title}</h1>
                    <div className="d-flex pt-3">
                        <h6 className="me-4 h6C">
                            <i className="bi bi-calendar-date-fill">&nbsp;</i>
                            {formatDate(blogdata.date)}
                        </h6>
                        {/* <h6 className="h6C">&nbsp;<i className="bi bi-chat-fill"></i>&nbsp;1</h6> */}
                    </div>
                </div>
                <div className="row pt-5 ps-md-3">
                    <div className="col-xl-8 p-3 pe-md-5">
                        <img src={blogimage} alt="Image1" className="w-100 object-fit-center" />
                        <div className="p-4 blog-details">
                            <h6 className="mt-4 lh-base text-white" dangerouslySetInnerHTML={{ __html: blogdata.content }} />
                        </div>
                    </div>
                    <div className="col-12 col-xl-4 ps-md-5">
                        <h4 className="pt-5 text-center">About Author</h4>
                        <div className="Author d-flex flex-column justify-content-center align-items-center p-3 mt-3">
                            <img src={User} alt="Author" className="w-100 " />
                            <h4 className="pt-3">Pradeep Kumar</h4>
                            <h6 className="h6C">Art Of Self Healing</h6>
                        </div>
                        <h4 className="pt-5 text-center">LATEST POSTS</h4>
                        <div className="Latest-blogs">
                            {latest.slice(0,2).map((latestBlog) => (
                                <div
                                    key={latestBlog.id}
                                    className="p-3 mt-4 blog-detail-card"
                                    onClick={() => handleBlogClick(latestBlog.slug)}
                                >
                                    <img src={latestBlog.background[0]} alt={latestBlog.title} className="h-50 w-100 object-fit-cover" />
                                    <h4 className="text-white pt-3">{latestBlog.title}</h4>
                                    <div className="d-flex justify-content-between mt-2">
                                        <h6 className="h6C">
                                            <i className="bi bi-calendar-date-fill">&nbsp;</i>
                                            {latestBlog.date}
                                        </h6>
                                        {/* <h6 className="h6C">
                                            <i className="bi bi-chat-fill"></i>&nbsp;{latestBlog.comments || 0}
                                        </h6> */}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default BlogDetail;
