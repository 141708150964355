import { useEffect } from "react";
import Footer from "../Components/Common/Footer/footer";
import Navbar from "../Components/Common/Navbar/Navbar";
import Live1 from "../Assets/Images/course1.png"
import Live2 from "../Assets/Images/course2.png"
import { useNavigate } from "react-router-dom";
import config from "./config";
import { Helmet } from "react-helmet";

const ReikiCertification = () => {

    const CourseContent = useNavigate()

    const courseDetails = [
        {
            key: "reiki-certification",
            title: "Usui Reiki Level 1 Certification",
            subtitle: "",
            description: "Embark on a transformative journey with our Usui Reiki Level 1 Certification Program, designed to introduce you to the ancient art of energy healing.",
            image: Live1,
        },
        {
            key: "usui-reiki-level-2-certification",
            title: "Usui Reiki Level 2 Certification",
            subtitle: "",
            description: "After Reiki Level 1 foundation, take your practice to new heights with our advanced Usui Reiki Level 2 Certification Program. This intensive, one-day course available as a personalized one-on-one session or a dynamic group class delves deeply into the art of energy healing.",
            image: Live2,
        },
        {
            key: "usui-reiki-level-3-course",
            title: "Usui Reiki Level 3 Certification",
            subtitle: "",
            description: "Embark on your journey to Reiki mastery and unlock the full potential of your healing abilities.",
            image: Live1,
        },
    ];

    const handleReadMore = (name) => {
        CourseContent(`${config.appUrl}live/${name}`); // Navigate to /blog/:name route (only name in URL)
    };


    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return(
        <div className="Live Courses">
            <Helmet>
                <title>Reiki Certification - artofselfhealing.ca</title>
                <meta name="description" content="" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Reiki Certification - artofselfhealing.ca" />
                <meta property="og:url" content="https://artofselfhealing.ca/reiki-certification1/" />
                <meta name="twitter:card" content="summary_large_image" />
		        <meta name="twitter:title" content="Reiki Certification - artofselfhealing.ca" />
            </Helmet>
            <Navbar cart={1}/>
                <div className="Section-main">
                    <div className="live-course-header my-5">
                        <h1 className="text-white py-5 ps-2 ps-md-4 text-center">Reiki Certification</h1>
                    </div>

                    <div className="Service-grid ms-xl-3">
                        {courseDetails.map((course, index) => (
                            <div className=" hover-container">
                                <img src={course.image} alt="Live1-image" className="w-100 object-fit-cover" />
                                    <h4 className="text-white mt-3 Main-Title">{course.title}</h4>
                                    {/* <h6 className="h6C text-white Main-Title Subtitle">{course.subtitle}</h6> */}
                                    <button className="card-work-button mt-4">See the Magic</button>
                                <div className="content text-start">
                                    <h3 className="text-white">{course.title}</h3>
                                    <h5 className="">{course.subtitle}</h5>
                                    <div className="d-flex">
                                            <h6 className="pt-1 ps-2 pb-1 h6C"> {course.description}</h6>
                                        </div>
                                    <button className="card-work-button" onClick={() => handleReadMore(course.key)}>Book the Course<span><i className="bi bi-chevron-right"></i></span></button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            <Footer/>
        </div>
    )

}

export default ReikiCertification;