
const Loader = () => {

    return(
        <div class="full-screen-loader">
            <div class="loader"></div>
        </div>

    )

}

export default Loader;